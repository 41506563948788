@charset "UTF-8";
.cc-window {
  opacity: 1;
  transition: opacity 1s ease; }

.cc-window.cc-invisible {
  opacity: 0; }

.cc-animate.cc-revoke {
  transition: transform 1s ease; }

.cc-animate.cc-revoke.cc-top {
  transform: translateY(-2em); }

.cc-animate.cc-revoke.cc-bottom {
  transform: translateY(2em); }

.cc-animate.cc-revoke.cc-active.cc-bottom,
.cc-animate.cc-revoke.cc-active.cc-top,
.cc-revoke:hover {
  transform: translateY(0); }

.cc-grower {
  max-height: 0;
  overflow: hidden;
  transition: max-height 1s; }

.cc-link,
.cc-revoke:hover {
  text-decoration: underline; }

.cc-revoke,
.cc-window {
  position: fixed;
  overflow: hidden;
  box-sizing: border-box;
  font-family: Helvetica, Calibri, Arial, sans-serif;
  font-size: 16px;
  line-height: 1.5em;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  z-index: 9999; }

.cc-window.cc-static {
  position: static; }

.cc-window.cc-floating {
  padding: 2em;
  max-width: 24em;
  -ms-flex-direction: column;
  flex-direction: column; }

.cc-window.cc-banner {
  padding: 1em 1.8em;
  width: 100%;
  -ms-flex-direction: row;
  flex-direction: row; }

.cc-revoke {
  padding: 0.5em; }

.cc-header {
  font-size: 18px;
  font-weight: 700; }

.cc-btn,
.cc-close,
.cc-link,
.cc-revoke {
  cursor: pointer; }

.cc-link {
  opacity: 0.8;
  display: inline-block;
  padding: 0.2em; }

.cc-link:hover {
  opacity: 1; }

.cc-link:active,
.cc-link:visited {
  color: initial; }

.cc-btn {
  display: block;
  padding: 0.4em 0.8em;
  font-size: 0.9em;
  font-weight: 700;
  border-width: 2px;
  border-style: solid;
  text-align: center;
  white-space: nowrap; }

.cc-banner .cc-btn:last-child {
  min-width: 140px; }

.cc-highlight .cc-btn:first-child {
  background-color: transparent;
  border-color: transparent; }

.cc-highlight .cc-btn:first-child:focus,
.cc-highlight .cc-btn:first-child:hover {
  background-color: transparent;
  text-decoration: underline; }

.cc-close {
  display: block;
  position: absolute;
  top: 0.5em;
  right: 0.5em;
  font-size: 1.6em;
  opacity: 0.9;
  line-height: 0.75; }

.cc-close:focus,
.cc-close:hover {
  opacity: 1; }

.cc-revoke.cc-top {
  top: 0;
  left: 3em;
  border-bottom-left-radius: 0.5em;
  border-bottom-right-radius: 0.5em; }

.cc-revoke.cc-bottom {
  bottom: 0;
  left: 3em;
  border-top-left-radius: 0.5em;
  border-top-right-radius: 0.5em; }

.cc-revoke.cc-left {
  left: 3em;
  right: unset; }

.cc-revoke.cc-right {
  right: 3em;
  left: unset; }

.cc-top {
  top: 1em; }

.cc-left {
  left: 1em; }

.cc-right {
  right: 1em; }

.cc-bottom {
  bottom: 1em; }

.cc-floating > .cc-link {
  margin-bottom: 1em; }

.cc-floating .cc-message {
  display: block;
  margin-bottom: 1em; }

.cc-window.cc-floating .cc-compliance {
  -ms-flex: 1;
  flex: 1; }

.cc-window.cc-banner {
  -ms-flex-align: center;
  align-items: center; }

.cc-banner.cc-top {
  left: 0;
  right: 0;
  top: 0; }

.cc-banner.cc-bottom {
  left: 0;
  right: 0;
  bottom: 0; }

.cc-banner .cc-message {
  -ms-flex: 1;
  flex: 1; }

.cc-compliance {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-line-pack: justify;
  align-content: space-between; }

.cc-compliance > .cc-btn {
  -ms-flex: 1;
  flex: 1; }

.cc-btn + .cc-btn {
  margin-left: 0.5em; }

@media print {
  .cc-revoke,
  .cc-window {
    display: none; } }

@media screen and (max-width: 900px) {
  .cc-btn {
    white-space: normal; } }

@media screen and (max-width: 414px) and (orientation: portrait), screen and (max-width: 736px) and (orientation: landscape) {
  .cc-window.cc-top {
    top: 0; }
  .cc-window.cc-bottom {
    bottom: 0; }
  .cc-window.cc-banner,
  .cc-window.cc-left,
  .cc-window.cc-right {
    left: 0;
    right: 0; }
  .cc-window.cc-banner {
    -ms-flex-direction: column;
    flex-direction: column; }
  .cc-window.cc-banner .cc-compliance {
    -ms-flex: 1;
    flex: 1; }
  .cc-window.cc-floating {
    max-width: none; }
  .cc-window .cc-message {
    margin-bottom: 1em; }
  .cc-window.cc-banner {
    -ms-flex-align: unset;
    align-items: unset; } }

.cc-floating.cc-theme-classic {
  padding: 1.2em;
  border-radius: 5px; }

.cc-floating.cc-type-info.cc-theme-classic .cc-compliance {
  text-align: center;
  display: inline;
  -ms-flex: none;
  flex: none; }

.cc-theme-classic .cc-btn {
  border-radius: 5px; }

.cc-theme-classic .cc-btn:last-child {
  min-width: 140px; }

.cc-floating.cc-type-info.cc-theme-classic .cc-btn {
  display: inline-block; }

.cc-theme-edgeless.cc-window {
  padding: 0; }

.cc-floating.cc-theme-edgeless .cc-message {
  margin: 2em 2em 1.5em; }

.cc-banner.cc-theme-edgeless .cc-btn {
  margin: 0;
  padding: 0.8em 1.8em;
  height: 100%; }

.cc-banner.cc-theme-edgeless .cc-message {
  margin-left: 1em; }

.cc-floating.cc-theme-edgeless .cc-btn + .cc-btn {
  margin-left: 0; }

/* http://meyerweb.com/eric/tools/css/reset/
   v2.0 | 20110126
   License: none (public domain)
*/
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline; }

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block; }

body {
  line-height: 1; }

ol,
ul {
  list-style: none; }

blockquote,
q {
  quotes: none; }

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

* {
  box-sizing: border-box; }

html,
body {
  background: #ece8dd;
  color: #7c8992;
  font-family: "Source Sans Pro", sans-serif;
  font-size: 24px;
  line-height: 34px; }
  html.nav-open,
  body.nav-open {
    position: relative;
    height: 100%;
    overflow: hidden; }
  @media only screen and (min-width: 900px) {
    html.nav-open,
    body.nav-open {
      overflow-y: auto; } }

.preload *:after {
  animation-duration: 0s !important;
  -webkit-animation-duration: 0s !important;
  transition: background-color 0s, opacity 0s, color 0s, width 0s, height 0s, padding 0s, margin 0s !important; }

.mobileOnly {
  display: block; }
  @media only screen and (min-width: 900px) {
    .mobileOnly {
      display: none; } }

.desktopOnly {
  display: none; }
  @media only screen and (min-width: 900px) {
    .desktopOnly {
      display: block; } }

ul {
  list-style: disc;
  padding-left: 20px; }
  ul li {
    margin-bottom: 5px; }

input {
  background: white;
  border-radius: 4px;
  border: none;
  display: inline-block;
  font-family: "Source Sans Pro", sans-serif;
  font-size: 15px;
  line-height: inherit;
  padding: 10px;
  text-align: left; }
  input:active, input:focus, input:visited {
    background: white;
    outline: none;
    border: none; }

.reset-global-styling ul {
  list-style: none;
  padding-left: 0; }
  .reset-global-styling ul li {
    margin-bottom: 0; }

.reset-global-styling input {
  font-size: medium;
  line-height: normal; }

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility; }

a {
  color: #d35663;
  font-weight: 600; }
  a:hover {
    color: #da717c; }
  a:active {
    color: #2e404f; }

h1,
h2,
h3,
h4,
h5,
.subtitle,
h6 {
  color: #2e404f;
  font-family: "Merriweather", serif; }
  h1.invert,
  h2.invert,
  h3.invert,
  h4.invert,
  h5.invert,
  .subtitle.invert,
  h6.invert {
    color: white; }

h1,
h2 {
  text-align: center; }
  @media only screen and (min-width: 900px) {
    h1,
    h2 {
      text-align: left; } }

h1 {
  font-size: 44px;
  line-height: 54px; }

h2 {
  font-size: 32px;
  line-height: 42px;
  margin-bottom: 20px; }

h3 {
  font-size: 28px;
  line-height: 38px;
  margin-bottom: 10px; }
  @media only screen and (min-width: 900px) {
    h3 {
      margin-bottom: 20px; } }

h4 {
  font-size: 24px;
  line-height: 34px;
  margin-bottom: 20px; }

strong {
  font-weight: bold; }

.subtitle {
  font-family: "Source Sans Pro", sans-serif;
  font-size: 0.75rem;
  font-weight: 600;
  letter-spacing: 2px;
  line-height: 22px;
  margin-bottom: 10px;
  text-align: center;
  text-transform: uppercase; }
  @media only screen and (min-width: 900px) {
    .subtitle {
      text-align: left; } }

.tagline {
  font-size: 1.15rem;
  font-family: "Source Sans Pro", sans-serif;
  line-height: 38px;
  margin-bottom: 20px;
  text-align: center; }
  @media only screen and (min-width: 900px) {
    .tagline {
      text-align: left; } }

.reset-global-styling h1,
.reset-global-styling h2 {
  text-align: left; }

.reset-global-styling h2 {
  margin: 0; }

.reset-global-styling h3 {
  margin: 0; }
  @media only screen and (min-width: 900px) {
    .reset-global-styling h3 {
      margin: 0; } }

.reset-global-styling h4 {
  margin: 0; }

.reset-global-styling .subtitle {
  margin-bottom: 0;
  text-align: left; }

.reset-global-styling .tagline {
  margin-bottom: 0;
  text-align: left; }

.how_it_works #benefits {
  position: relative; }
  .how_it_works #benefits img.furniture {
    display: none; }
    @media only screen and (min-width: 900px) {
      .how_it_works #benefits img.furniture {
        display: block;
        position: absolute;
        bottom: -100px;
        left: -40px;
        height: auto;
        width: 300px;
        z-index: 5;
        transform: scale(0.9); } }
  .how_it_works #benefits.animating img.furniture {
    display: none; }
  .how_it_works #benefits.animating #purse-animation {
    display: none; }
    @media only screen and (min-width: 900px) {
      .how_it_works #benefits.animating #purse-animation {
        background: transparent url(/static/images/animations/purse-animation.b7fbcc623b08c29f271e34fc444113f4.png) top left no-repeat;
        background-size: auto 300px;
        display: block;
        position: absolute;
        bottom: -120px;
        left: -40px;
        height: 300px;
        width: 300px;
        z-index: 5;
        transform: scale(0.8); } }

.services .types-of-specialist {
  position: relative; }
  @media only screen and (min-width: 900px) {
    .services .types-of-specialist:after {
      background: transparent url(/static/images/illustrations/furniture/blue-dog-right.3517da8d5ecbee3601237d0808d85ee5.png) top left no-repeat;
      background-size: contain;
      content: "";
      display: block;
      position: absolute;
      bottom: -34px;
      left: 5%;
      height: 180px;
      width: 180px;
      z-index: 5; } }

.customer-cta-1 {
  position: relative; }

@media only screen and (min-width: 900px) {
  .caring-for-britain .pane {
    background: white url(/static/images/illustrations/furniture/pills-shadow.8f49fb3db36cfcd9d0d639f759bb8f85.png) right 20px bottom no-repeat;
    background-size: 30% auto; } }

.care_jobs .how-it-works {
  position: relative; }
  @media only screen and (min-width: 900px) {
    .care_jobs .how-it-works:after {
      background: transparent url(/static/images/illustrations/furniture/flower-pot.6142c5ed96211d1c6f4f4bc872e0fe1b.png) top left no-repeat;
      background-size: contain;
      content: "";
      display: block;
      position: absolute;
      bottom: -50px;
      right: 1%;
      height: 200px;
      width: 150px;
      z-index: 5; } }

#testimonials #coffee {
  margin: -20px auto 20px; }

#testimonials.animating #coffee {
  display: none; }

#testimonials.animating #coffee-animation {
  display: none; }
  @media only screen and (min-width: 900px) {
    #testimonials.animating #coffee-animation {
      background: transparent url(/static/images/animations/coffee-animation.da90421f4efb92efeb9db2ebc2381f1b.png) top left no-repeat;
      background-size: auto 168px;
      clear: both;
      display: inline-block;
      margin: -20px auto 20px;
      height: 168px;
      width: 250px; } }

.customer-cta-1 .animating .phone {
  display: none; }

.customer-cta-1 .animating #phone-animation {
  display: none; }
  @media only screen and (min-width: 900px) {
    .customer-cta-1 .animating #phone-animation {
      background: transparent url(/static/images/animations/phone-animation.c951cedfbe1d4001b71659b7d799d183.png) top left no-repeat;
      background-size: auto 180px;
      clear: both;
      display: inline-block;
      margin: -28px auto;
      height: 180px;
      width: 240px;
      transform: scale(0.7); } }

@media only screen and (min-width: 900px) {
  .what-is-supercarers .pane {
    background-size: 60% auto; } }

.InfinityNumber {
  cursor: pointer; }

.wrap {
  border-color: transparent;
  border-image-slice: 9;
  border-image-repeat: round;
  border-image-outset: 10px;
  border-image-width: 10px 0 0 0;
  border-top-style: solid;
  border-top-width: 10px;
  border-image-source: url(/static/images/structure/cream-border.82e04649f5f7df9a753e53bc25d8e53c.png);
  position: relative; }
  @media only screen and (min-width: 900px) {
    .wrap {
      border-image-repeat: repeat; } }
  .wrap.extends {
    overflow: hidden; }
  .wrap > * {
    margin: 0 auto; }
    @media only screen and (min-width: 900px) {
      .wrap > * {
        max-width: 1360px; } }
  .wrap > section {
    padding: 20px 0 30px; }
    .wrap > section::after {
      clear: both;
      content: "";
      display: block; }
    @media only screen and (min-width: 900px) {
      .wrap > section {
        padding: 80px 0 90px; }
        .wrap > section + section {
          padding: 0; }
        .wrap > section + section:last-child {
          padding: 0 0 80px; } }
  .wrap button {
    appearance: none;
    border: none;
    outline: none;
    display: inline-block;
    cursor: pointer;
    font-family: "Source Sans Pro", sans-serif;
    font-weight: 600;
    padding: 10px 30px; }
  .wrap p {
    margin-bottom: 20px; }
    .wrap p:last-child {
      margin-bottom: 0; }
  .wrap.blue {
    background-color: #56a6d1;
    border-image-source: url(/static/images/structure/blue-border.d579962a0b5b9ac187c8c04d29cbca97.png);
    color: white; }
    .wrap.blue h2,
    .wrap.blue h3,
    .wrap.blue h5,
    .wrap.blue p {
      color: white; }
  .wrap.red {
    background-color: #d35663;
    border-image-source: url(/static/images/structure/red-border.f4f87902ff4190ac9d01cbb50cc0c5a6.png);
    color: #dadada; }
    .wrap.red h2,
    .wrap.red h3,
    .wrap.red h5,
    .wrap.red p {
      color: white; }
    .wrap.red a,
    .wrap.red span {
      color: white;
      font-weight: 700;
      text-decoration: none; }
  .wrap.navy {
    background-color: #2e404f;
    border-image-source: url(/static/images/structure/navy-border.2e97437d80dd35831bc8b272fd1596da.png);
    color: white; }
    .wrap.navy h2,
    .wrap.navy h3,
    .wrap.navy h5,
    .wrap.navy p.subtitle,
    .wrap.navy p {
      color: #c9cfd4; }
  .wrap.darker-navy {
    background-color: #293947;
    border-image-source: url(/static/images/structure/darker-navy-border.ed1848aee11934b83bb010542b88c0c2.png);
    color: rgba(255, 255, 255, 0.5); }
    .wrap.darker-navy h2,
    .wrap.darker-navy h3,
    .wrap.darker-navy p.subtitle,
    .wrap.darker-navy h5 {
      color: white; }
  .wrap.milk {
    background-color: #f5f3ee;
    border-image-source: url(/static/images/structure/milk-border.2ceae81b2b1ac86cf4bda746e50684ed.png); }
  .wrap.white {
    background-color: white;
    border-top: 0; }
  .wrap.grey {
    background-color: #dadada;
    border-image-source: url(/static/images/structure/grey-border.93a5860b3ac1ccb644b3a28561ddec37.png); }
  .wrap .pane {
    background: white;
    border-radius: 2px;
    color: #7c8992;
    margin-bottom: 20px;
    padding: 40px 20px; }
    @media only screen and (min-width: 900px) {
      .wrap .pane {
        margin-bottom: 40px;
        padding: 50px; } }
    .wrap .pane:last-child {
      margin-bottom: 0; }
    .wrap .pane h3,
    .wrap .pane h5.subtitle,
    .wrap .pane p.subtitle {
      color: #2e404f; }
    .wrap .pane p {
      color: #7c8992;
      margin-bottom: 20px;
      text-align: center; }
      .wrap .pane p:last-child {
        margin-bottom: 0; }
    .wrap .pane .content {
      text-align: left; }
    .wrap .pane > .link-footer {
      font-size: 18px;
      line-height: 28px;
      text-align: center; }
      @media only screen and (min-width: 900px) {
        .wrap .pane > .link-footer {
          text-align: left; } }
      .wrap .pane > .link-footer a {
        font-weight: 600; }
    .wrap .pane.side-by-side p {
      text-align: left; }

.col {
  width: calc(100% - 30px);
  float: left;
  margin-left: 15px;
  text-align: center; }
  @media only screen and (min-width: 900px) {
    .col {
      width: calc(100% - 64px);
      float: left;
      margin-left: 32px;
      text-align: left; } }
  @media only screen and (min-width: 900px) {
    .col.slim {
      width: calc(66.66667% - 53.33333px);
      float: left;
      margin-left: 32px;
      margin-left: calc(16.66667% - 37.33333px + 64px);
      text-align: left; } }
  .col.slim:after {
    clear: both;
    content: "";
    display: block; }

.col-central {
  width: calc(100% - 30px);
  float: left;
  margin-left: 15px;
  text-align: center; }
  @media only screen and (min-width: 900px) {
    .col-central {
      width: calc(66.66667% - 53.33333px);
      float: left;
      margin-left: 32px;
      margin-left: calc(16.66667% - 37.33333px + 64px);
      text-align: center; }
      .col-central.slim {
        width: calc(50% - 48px);
        float: left;
        margin-left: 32px;
        margin-left: calc(25% - 40px + 64px); }
        .col-central.slim:after {
          clear: both;
          content: "";
          display: block; }
      .col-central h1,
      .col-central h2,
      .col-central h3,
      .col-central h4,
      .col-central h5,
      .col-central h5.subtitle,
      .col-central p.subtitle,
      .col-central h6,
      .col-central .tagline p {
        text-align: center; } }
  .col-central h1 + .hero-subtitle {
    margin-top: 20px; }

.col-inset {
  width: calc(100% - 30px);
  float: left;
  margin-left: 15px; }
  @media only screen and (min-width: 900px) {
    .col-inset {
      width: calc(100% - 64px);
      float: left;
      margin-left: 32px; } }
  @media only screen and (min-width: 1060px) {
    .col-inset {
      width: calc(83.33333% - 58.66667px);
      float: left;
      margin-left: 32px;
      margin-left: calc(8.33333% - 34.66667px + 64px); } }
  @media only screen and (min-width: 900px) {
    .col-inset.central {
      text-align: center; } }
  .col-inset.col-trisect {
    margin-left: -15px;
    margin-right: -15px;
    width: calc(100% + 30px); }

.col-asym {
  width: calc(100% - 30px);
  float: left;
  margin-left: 15px;
  text-align: center; }
  @media only screen and (min-width: 900px) {
    .col-asym {
      width: calc(50% - 48px);
      float: left;
      margin-left: 32px; }
      .col-asym:first-child {
        text-align: left;
        width: calc(41.66667% - 45.33333px);
        float: left;
        margin-left: 32px; } }
  @media only screen and (min-width: 1060px) {
    .col-asym {
      width: calc(50% - 48px);
      float: left;
      margin-left: 32px; }
      .col-asym:first-child {
        text-align: left;
        width: calc(33.33333% - 42.66667px);
        float: left;
        margin-left: 32px;
        margin-left: calc(8.33333% - 34.66667px + 64px); } }

.col-bisect {
  width: calc(100% - 30px);
  float: left;
  margin-left: 15px; }
  @media only screen and (min-width: 900px) {
    .col-bisect {
      width: calc(50% - 48px);
      float: left;
      margin-left: 32px; }
      .col-bisect:nth-of-type(odd) {
        clear: left; } }
  @media only screen and (min-width: 1060px) {
    .col-bisect {
      width: calc(41.66667% - 45.33333px);
      float: left;
      margin-left: 32px; }
      .col-bisect:nth-of-type(odd) {
        margin-left: calc(8.33333% - 34.66667px + 64px);
        clear: left; } }
  .col-bisect.info {
    text-align: center; }
    @media only screen and (min-width: 900px) {
      .col-bisect.info {
        text-align: left; } }
  .col-bisect > img {
    max-width: 100%; }

@media only screen and (min-width: 1060px) {
  .col-central + .col-bisect {
    margin-left: calc(8.33333% - 34.66667px + 64px); } }

.col-trisect {
  width: calc(100% - 30px);
  float: left;
  margin-left: 15px; }
  @media only screen and (min-width: 900px) {
    .col-trisect {
      width: calc(33.33333% - 42.66667px);
      float: left;
      margin-left: 32px; }
      .col-trisect:nth-child(n + 4) {
        margin-top: 60px; }
      .col-trisect:nth-child(3n + 1) {
        clear: left; } }

.col-quadsect {
  width: calc(100% - 30px);
  float: left;
  margin-left: 15px; }
  @media only screen and (min-width: 900px) {
    .col-quadsect {
      width: calc(25% - 40px);
      float: left;
      margin-left: 32px; }
      .col-quadsect:nth-child(n + 5) {
        margin-top: 20px; }
      .col-quadsect:nth-child(4n + 1) {
        clear: left; } }

/**
* LIST STYLING
*/
@media only screen and (min-width: 900px) {
  ul.bisect {
    column-count: 2;
    column-gap: 32px;
    padding-left: 0; }
  ul.trisect {
    column-count: 3;
    column-gap: 32px;
    padding-left: 0; } }

ul.link-list {
  list-style: none;
  padding-left: 0;
  margin: 10px 0; }
  ul.link-list li {
    background: none;
    margin-bottom: 0;
    padding: 0;
    margin: 5px 0; }
    ul.link-list li a {
      font-size: 18px; }

ul.checklist {
  list-style: none;
  padding-left: 0; }
  ul.checklist li {
    background: url(/static/images/icons/checkmark.7ee7f97579e3c4864d7bba57265adc43.png) no-repeat left 8px;
    background-size: 20px 20px;
    break-inside: avoid-column;
    padding-left: 30px; }

ul.pagination {
  margin-top: 40px; }
  ul.pagination li {
    display: inline-block;
    font-size: 18px;
    margin: 0 5px; }
    ul.pagination li.selected a {
      color: #2e404f;
      text-decoration: none; }
    ul.pagination li:first-child a,
    ul.pagination li:last-child a {
      text-decoration: none; }

ul li.bullet {
  background-position: 20px 8px;
  text-align: left;
  padding-left: 60px;
  margin-bottom: 40px; }
  @media only screen and (min-width: 900px) {
    ul li.bullet {
      margin-bottom: 60px; } }
  ul li.bullet:last-child {
    margin-bottom: 0; }

ul li.link {
  font-size: 16px;
  line-height: 36px;
  font-weight: 700; }

/**
* IMAGE STYLING
*/
img.circle {
  border-radius: 50%; }

img.furniture {
  clear: both;
  display: none;
  margin: 20px auto 0; }
  @media only screen and (min-width: 900px) {
    img.furniture {
      display: inline-block; } }

/**
* BUTTON STYLING -  KEEPING
*/
button.btn,
a.btn {
  font-family: "Source Sans Pro", sans-serif;
  line-height: inherit;
  background: #d35663;
  border-radius: 4px;
  border: none;
  color: white;
  cursor: pointer;
  font-size: 20px;
  font-weight: 600;
  display: inline-block;
  padding: 10px 30px;
  text-decoration: none;
  transform: translateY(0);
  transition: all 0.3s ease-in-out;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0); }
  button.btn:hover,
  a.btn:hover {
    background: #da717c;
    color: white;
    box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.4);
    transform: translateY(-2px); }
  button.btn:active,
  a.btn:active {
    background: #2e404f; }
  button.btn.alt,
  a.btn.alt {
    background: transparent;
    border: 2px solid #d35663;
    color: #d35663; }
    button.btn.alt:hover,
    a.btn.alt:hover {
      border-color: #da717c;
      color: #da717c; }
    button.btn.alt:active,
    a.btn.alt:active {
      background: #2e404f;
      border-color: #2e404f;
      color: white; }
  button.btn.invert,
  a.btn.invert {
    background: white;
    color: #d35663; }
    button.btn.invert:hover,
    a.btn.invert:hover {
      border-color: #da717c;
      color: #da717c; }
    button.btn.invert:active,
    a.btn.invert:active {
      background: #2e404f;
      color: white; }
  button.btn.white-navy,
  a.btn.white-navy {
    background: white;
    color: #2e404f; }
    button.btn.white-navy:hover,
    a.btn.white-navy:hover {
      background: white;
      color: #2e404f; }
    button.btn.white-navy:active,
    a.btn.white-navy:active {
      background: #2e404f;
      color: white; }
  button.btn.navy-white,
  a.btn.navy-white {
    color: white;
    background: #2e404f; }
    button.btn.navy-white:hover,
    a.btn.navy-white:hover {
      color: white;
      background: #2e404f; }
    button.btn.navy-white:active,
    a.btn.navy-white:active {
      color: white;
      background: #2e404f; }

/**
* FORM STYLING
*/
form.inline {
  clear: both; }
  form.inline::after {
    clear: both;
    content: "";
    display: block; }
  form.inline input {
    width: calc(100% - 30px);
    float: left;
    margin-left: 15px;
    margin-bottom: 20px;
    -webkit-appearance: none; }
    @media only screen and (min-width: 900px) {
      form.inline input {
        width: calc(41.66667% - 45.33333px);
        float: left;
        margin-left: 32px;
        margin-left: calc(16.66667% - 37.33333px + 64px); } }
  @media only screen and (min-width: 900px) and (min-width: 0\0) {
    form.inline input {
      /* IE9 and IE10 rule sets go here */
      height: 54px; } }
  form.inline button {
    width: calc(100% - 30px);
    float: left;
    margin-left: 15px; }
    @media only screen and (min-width: 900px) {
      form.inline button {
        width: calc(25% - 40px);
        float: left;
        margin-left: 32px; } }

/**
* UTILS
*/
.blur {
  filter: blur(5px); }

.logo.navy > a {
  color: #2e404f; }

.logo.white > a {
  color: #ffffff; }

h1 {
  font-size: 1.5rem; }
  @media (min-width: 1024px) {
    h1 {
      font-size: 1.8rem; } }

.fuzzy-milk-border {
  border-image-source: url(/static/images/structure/milk-border.2ceae81b2b1ac86cf4bda746e50684ed.png);
  border-image-slice: 9;
  border-image-outset: 9px 0;
  border-image-width: 10px 0 0 0;
  border-top-style: solid;
  border-top-width: 10px; }

.fuzzy-cream-border {
  border-image-source: url(/static/images/structure/cream-border.82e04649f5f7df9a753e53bc25d8e53c.png);
  border-image-slice: 9;
  border-image-outset: 9px 0;
  border-image-width: 10px 0 0 0;
  border-top-style: solid;
  border-top-width: 10px; }

.fuzzy-red-border {
  border-image-source: url(/static/images/structure/red-border.f4f87902ff4190ac9d01cbb50cc0c5a6.png);
  border-image-slice: 9;
  border-image-outset: 9px 0;
  border-image-width: 10px 0 0 0;
  border-top-style: solid;
  border-top-width: 10px; }

.two-col__container {
  display: flex;
  flex-direction: column; }
  @media (min-width: 1024px) {
    .two-col__container {
      flex-direction: row;
      align-items: center; } }

.two-col__image {
  text-align: center; }
  @media (min-width: 1024px) {
    .two-col__image {
      flex: 0 0 50%;
      margin: auto;
      padding: 1rem; } }
  .two-col__image img {
    width: 100%; }

@media (min-width: 1024px) {
  .two-col__text {
    flex: 0 0 50%; } }

.advisory-board__intro {
  display: flex;
  flex-direction: column;
  align-items: center; }

.advisory-board__profiles {
  display: flex;
  flex-wrap: wrap; }

.advisory-board__board-profile {
  flex: 0 0 100%;
  display: flex;
  align-items: center; }
  @media (min-width: 768px) {
    .advisory-board__board-profile {
      flex: 0 0 25%;
      flex-direction: column;
      text-align: center; } }

.advisory-board__member-image {
  max-width: 25%; }
  @media (min-width: 768px) {
    .advisory-board__member-image {
      max-width: 50%; } }

.advisory-board__member-name {
  color: #2e404f;
  font-size: 0.9rem; }

.advisory-board__accolade {
  font-size: 0.7rem; }

.advisory-board__board-description {
  font-size: 0.8rem; }

.post-pane {
  background: white;
  border-radius: 4px;
  margin-top: 30px;
  overflow: hidden; }
  @media only screen and (min-width: 900px) {
    .post-pane {
      min-height: 700px; } }
  .post-pane > a.image-link {
    display: block; }
    .post-pane > a.image-link > img {
      display: block; }
  .post-pane .content {
    padding: 30px 40px; }
    .post-pane .content h4 {
      margin-bottom: 0; }
      .post-pane .content h4 a {
        color: #2e404f;
        font-weight: 500;
        text-decoration: none; }
        .post-pane .content h4 a:hover {
          color: #d35663; }
    .post-pane .content img {
      display: none; }
    .post-pane .content span.date {
      display: block;
      font-size: 15px;
      font-weight: 400;
      margin-bottom: 20px; }
      .post-pane .content span.date strong {
        font-weight: 600; }
    .post-pane .content > a {
      display: block;
      margin-top: 20px;
      font-size: 18px; }
    .post-pane .content > span,
    .post-pane .content > p {
      color: #7c8992; }

.post .post-section > .post-header,
.question .post-section > .post-header {
  width: calc(100% - 30px);
  float: left;
  margin-left: 15px;
  position: relative; }
  @media only screen and (min-width: 900px) {
    .post .post-section > .post-header,
    .question .post-section > .post-header {
      width: calc(83.33333% - 58.66667px);
      float: left;
      margin-left: 32px;
      margin-left: calc(8.33333% - 34.66667px + 64px);
      margin-top: 40px; } }
  .post .post-section > .post-header > p:first-of-type,
  .question .post-section > .post-header > p:first-of-type {
    font-size: 28px;
    line-height: 38px; }
  .post .post-section > .post-header img:first-child,
  .post .post-section > .post-header a.video,
  .question .post-section > .post-header img:first-child,
  .question .post-section > .post-header a.video {
    display: block;
    margin: 0 auto 20px;
    width: 100%; }
    @media only screen and (min-width: 900px) {
      .post .post-section > .post-header img:first-child,
      .post .post-section > .post-header a.video,
      .question .post-section > .post-header img:first-child,
      .question .post-section > .post-header a.video {
        margin-bottom: 40px;
        width: 75%; } }
  .post .post-section > .post-header a.video,
  .question .post-section > .post-header a.video {
    position: absolute;
    top: calc(50% - 50px);
    left: calc(50% - 50px);
    background-image: url(/static/images/icons/play-button-white.6f997c9170aa0a51a90e4fd73e24b1d9.svg);
    background-position: 27px center;
    background-size: 50px 50px;
    background-repeat: no-repeat;
    border: 6px solid white;
    border-radius: 100px;
    color: white;
    display: block;
    margin: 0;
    height: 100px;
    text-indent: -9999em;
    width: 100px; }
    .post .post-section > .post-header a.video:hover,
    .question .post-section > .post-header a.video:hover {
      opacity: 0.8; }
    .post .post-section > .post-header a.video:active,
    .question .post-section > .post-header a.video:active {
      background-image: url(/static/images/icons/play-button-navy.67f814860e6d382b3bc7b3d4db8e2a49.svg);
      opacity: 1;
      border-color: #2e404f; }
    @media only screen and (min-width: 900px) {
      .post .post-section > .post-header a.video,
      .question .post-section > .post-header a.video {
        top: calc(50% - 75px); } }
  .post .post-section > .post-header iframe,
  .question .post-section > .post-header iframe {
    display: block;
    margin: 0 auto 10px;
    width: 100%; }
    @media only screen and (min-width: 900px) {
      .post .post-section > .post-header iframe,
      .question .post-section > .post-header iframe {
        margin: 0 auto 40px;
        width: 75%; } }

.post .post-section > .post-summary,
.question .post-section > .post-summary {
  width: calc(100% - 30px);
  float: left;
  margin-left: 15px;
  padding: 0 10px;
  position: relative;
  margin-bottom: 20px; }
  @media only screen and (min-width: 900px) {
    .post .post-section > .post-summary,
    .question .post-section > .post-summary {
      padding: 0 20px; } }
  .post .post-section > .post-summary p,
  .question .post-section > .post-summary p {
    clear: both; }
  .post .post-section > .post-summary .share,
  .question .post-section > .post-summary .share {
    margin: 20px auto 0;
    text-align: center; }
    .post .post-section > .post-summary .share a,
    .question .post-section > .post-summary .share a {
      display: inline-block; }
      .post .post-section > .post-summary .share a img,
      .question .post-section > .post-summary .share a img {
        display: inline-block;
        margin-left: 10px; }
  @media only screen and (min-width: 900px) {
    .post .post-section > .post-summary,
    .question .post-section > .post-summary {
      width: calc(66.66667% - 53.33333px);
      float: left;
      margin-left: 32px;
      margin-left: calc(16.66667% - 37.33333px + 64px);
      border-left: 1px solid #dadada;
      margin-bottom: 0;
      margin-top: 40px; }
      .post .post-section > .post-summary .share,
      .question .post-section > .post-summary .share {
        float: none;
        position: absolute;
        margin: -60px 0 0;
        left: -80px;
        top: 50%; }
        .post .post-section > .post-summary .share > p:last-child,
        .question .post-section > .post-summary .share > p:last-child {
          margin-bottom: 0; }
        .post .post-section > .post-summary .share > a,
        .question .post-section > .post-summary .share > a {
          display: block;
          margin-bottom: 20px; }
          .post .post-section > .post-summary .share > a img,
          .question .post-section > .post-summary .share > a img {
            color: #d35663;
            display: block; }
          .post .post-section > .post-summary .share > a:last-child,
          .question .post-section > .post-summary .share > a:last-child {
            margin-bottom: 0; } }

.post .post-section > article,
.question .post-section > article {
  width: calc(100% - 30px);
  float: left;
  margin-left: 15px;
  background: white;
  border-radius: 4px;
  clear: both;
  padding: 20px 20px 40px;
  position: relative; }
  @media only screen and (min-width: 900px) {
    .post .post-section > article,
    .question .post-section > article {
      width: calc(100% - 64px);
      float: left;
      margin-left: 32px;
      margin-left: calc(0% - 32px + 64px);
      padding: 20px 40px 40px; } }
  .post .post-section > article .read-time,
  .question .post-section > article .read-time {
    background: white url(/static/images/illustrations/furniture/blue-dot.461ed7122f0c85dbf3a19872b7f8adb3.png) -1px -7px no-repeat;
    border-radius: 50%;
    color: #2e404f;
    display: none;
    float: right;
    font-family: "Merriweather", serif;
    font-weight: bold;
    font-size: 22px;
    line-height: 28px;
    height: 160px;
    padding: 52px 40px 0;
    position: relative;
    text-align: center;
    margin: -100px 0 0px 10px;
    width: 160px; }
    @media only screen and (min-width: 900px) {
      .post .post-section > article .read-time,
      .question .post-section > article .read-time {
        display: block; } }
  .post .post-section > article h2,
  .post .post-section > article h3,
  .post .post-section > article h4,
  .question .post-section > article h2,
  .question .post-section > article h3,
  .question .post-section > article h4 {
    text-align: left; }
  .post .post-section > article img,
  .post .post-section > article iframe,
  .question .post-section > article img,
  .question .post-section > article iframe {
    max-width: 100%; }

.post .post-section h2,
.post .post-section h3,
.post .post-section h4,
.question .post-section h2,
.question .post-section h3,
.question .post-section h4 {
  margin-top: 20px; }

.post .post-section ul,
.post .post-section ol,
.question .post-section ul,
.question .post-section ol {
  margin: 40px 20px; }
  .post .post-section ul > li,
  .post .post-section ol > li,
  .question .post-section ul > li,
  .question .post-section ol > li {
    margin-bottom: 10px; }
  .post .post-section ul ul,
  .post .post-section ol ul,
  .question .post-section ul ul,
  .question .post-section ol ul {
    margin: 20px; }

.post .post-section ol,
.question .post-section ol {
  list-style: decimal; }

.post .post-section blockquote,
.question .post-section blockquote {
  border-bottom: 1px solid #dadada;
  border-top: 1px solid #dadada;
  color: #d35663;
  font-size: 28px;
  line-height: 38px;
  margin: 30px 0;
  padding: 20px 0;
  text-align: center; }
  .post .post-section blockquote p,
  .question .post-section blockquote p {
    margin-left: auto;
    margin-right: auto;
    width: 75%; }
    @media only screen and (min-width: 900px) {
      .post .post-section blockquote p,
      .question .post-section blockquote p {
        width: 60%; } }
    .post .post-section blockquote p:first-child,
    .question .post-section blockquote p:first-child {
      position: relative; }
      .post .post-section blockquote p:first-child:before,
      .question .post-section blockquote p:first-child:before {
        background: transparent url(/static/images/icons/quote-open@2x.ab265a5557c21089a5d2eb4591dace88.png) center center no-repeat;
        background-size: 40px auto;
        content: " ";
        height: 40px;
        display: block;
        position: absolute;
        left: -50px;
        top: -10px;
        width: 40px; }
    .post .post-section blockquote p:last-child,
    .question .post-section blockquote p:last-child {
      position: relative; }
      .post .post-section blockquote p:last-child:after,
      .question .post-section blockquote p:last-child:after {
        background: transparent url(/static/images/icons/quote-close@2x.dfdb17754406daef978378669b1b2818.png) center center no-repeat;
        background-size: 40px auto;
        content: " ";
        height: 40px;
        display: block;
        position: absolute;
        right: -50px;
        bottom: -10px;
        width: 40px; }
  @media only screen and (min-width: 900px) {
    .post .post-section blockquote,
    .question .post-section blockquote {
      padding: 30px 0; } }

.post .page-header .date,
.post .page-header .subhead,
.question .page-header .date,
.question .page-header .subhead {
  display: inline-block;
  font-size: 18px;
  margin-top: 10px; }

.supercarers-go .page-content > div,
.simple .page-content > div,
.index .page-content > div {
  width: calc(100% - 30px);
  float: left;
  margin-left: 15px; }
  @media only screen and (min-width: 900px) {
    .supercarers-go .page-content > div,
    .simple .page-content > div,
    .index .page-content > div {
      width: calc(50% - 48px);
      float: left;
      margin-left: 32px;
      margin-left: calc(25% - 40px + 64px); } }
  .supercarers-go .page-content > div > p:first-of-type,
  .simple .page-content > div > p:first-of-type,
  .index .page-content > div > p:first-of-type {
    font-size: 28px;
    line-height: 38px; }
  .supercarers-go .page-content > div img:first-child,
  .simple .page-content > div img:first-child,
  .index .page-content > div img:first-child {
    width: 100%; }
    @media only screen and (min-width: 900px) {
      .supercarers-go .page-content > div img:first-child,
      .simple .page-content > div img:first-child,
      .index .page-content > div img:first-child {
        margin-left: -50vw;
        margin-right: -50vw;
        max-width: 100vw;
        position: relative;
        right: -50%;
        width: 100vw; } }

.supercarers-go .page-content h2,
.supercarers-go .page-content h3,
.supercarers-go .page-content h4,
.simple .page-content h2,
.simple .page-content h3,
.simple .page-content h4,
.index .page-content h2,
.index .page-content h3,
.index .page-content h4 {
  margin-top: 20px; }

.supercarers-go .page-content ul,
.supercarers-go .page-content ol,
.simple .page-content ul,
.simple .page-content ol,
.index .page-content ul,
.index .page-content ol {
  margin: 40px 0; }
  .supercarers-go .page-content ul > li,
  .supercarers-go .page-content ol > li,
  .simple .page-content ul > li,
  .simple .page-content ol > li,
  .index .page-content ul > li,
  .index .page-content ol > li {
    margin-bottom: 10px; }
  .supercarers-go .page-content ul ul,
  .supercarers-go .page-content ol ul,
  .simple .page-content ul ul,
  .simple .page-content ol ul,
  .index .page-content ul ul,
  .index .page-content ol ul {
    margin: 20px; }

.supercarers-go .page-content ol,
.simple .page-content ol,
.index .page-content ol {
  list-style: decimal; }

.supercarers-go .page-content blockquote,
.simple .page-content blockquote,
.index .page-content blockquote {
  border-left: 5px solid #56a6d1;
  font-size: 28px;
  line-height: 38px;
  margin: 30px 0;
  padding-left: 20px; }

.supercarers-go .page-header .date,
.supercarers-go .page-header .subhead,
.simple .page-header .date,
.simple .page-header .subhead,
.index .page-header .date,
.index .page-header .subhead {
  display: inline-block;
  font-size: 18px; }

.simple .page-content > div {
  width: calc(100% - 30px);
  float: left;
  margin-left: 15px; }
  @media only screen and (min-width: 900px) {
    .simple .page-content > div {
      width: calc(66.66667% - 53.33333px);
      float: left;
      margin-left: 32px;
      margin-left: calc(16.66667% - 37.33333px + 64px); } }
  .simple .page-content > div ul ul {
    list-style: circle; }

.carer-guide__container {
  margin: 1rem 0 0;
  color: #4b4d52; }

.carer-guide__title {
  max-width: 768px;
  margin: auto;
  text-align: center;
  padding: 0.5rem; }
  .carer-guide__title {
    font-size: 24px;
    line-height: 33.6px; }
    @media screen and (min-width: 500px) {
      .carer-guide__title {
        font-size: calc( 24px + 12 * ((100vw - 500px) / 268));
        line-height: calc( ( 24px + 12 * ((100vw - 500px) / 268) ) * 1.4); } }
    @media screen and (min-width: 768px) {
      .carer-guide__title {
        font-size: 36px;
        line-height: 50.4px; } }

.carer-guide__date {
  text-align: center;
  padding: 0 0.5rem 1rem; }
  .carer-guide__date {
    font-size: 18px;
    line-height: 25.2px; }
    @media screen and (min-width: 500px) {
      .carer-guide__date {
        font-size: calc( 18px + 2 * ((100vw - 500px) / 268));
        line-height: calc( ( 18px + 2 * ((100vw - 500px) / 268) ) * 1.4); } }
    @media screen and (min-width: 768px) {
      .carer-guide__date {
        font-size: 20px;
        line-height: 28px; } }

.carer-guide__hero {
  max-width: 1024px;
  display: flex;
  margin: auto;
  position: relative; }

.carer-guide__hero-image {
  height: 100%;
  width: 100%; }

.carer-guide__hero-image-caption {
  position: absolute;
  top: 101%;
  left: 0.25rem;
  font-size: 0.65rem;
  line-height: 0.8rem;
  color: #4b4d52;
  max-width: 350px; }
  @media (min-width: 768px) {
    .carer-guide__hero-image-caption {
      max-width: 100%; } }
  @media (min-width: 1024px) {
    .carer-guide__hero-image-caption {
      left: 0; } }

.carer-guide__article {
  background: white; }

.carer-guide__read-time {
  display: none;
  background: white url(/static/images/illustrations/furniture/blue-dot-squared.d0d23ed834fe67b646f55c096617fe97.png) center center no-repeat;
  border-radius: 50%;
  color: #2e404f;
  height: 160px;
  position: relative;
  width: 160px;
  justify-content: center;
  align-items: center;
  float: right;
  font-size: 0.8rem;
  font-weight: 700;
  font-family: "Merriweather", serif;
  margin: -130px 0.5rem 0 0; }
  @media (min-width: 500px) {
    .carer-guide__read-time {
      display: flex; } }

.carer-guide__article-content {
  max-width: 1024px;
  margin: auto;
  padding: 1rem 0 2rem; }
  .carer-guide__article-content {
    font-size: 18px;
    line-height: 25.2px; }
    @media screen and (min-width: 500px) {
      .carer-guide__article-content {
        font-size: calc( 18px + 6 * ((100vw - 500px) / 268));
        line-height: calc( ( 18px + 6 * ((100vw - 500px) / 268) ) * 1.4); } }
    @media screen and (min-width: 768px) {
      .carer-guide__article-content {
        font-size: 24px;
        line-height: 33.6px; } }
  .carer-guide__article-content > p,
  .carer-guide__article-content blockquote,
  .carer-guide__article-content h2,
  .carer-guide__article-content h3,
  .carer-guide__article-content h4 {
    padding: 0.5rem 0.75rem; }
    @media (min-width: 500px) {
      .carer-guide__article-content > p,
      .carer-guide__article-content blockquote,
      .carer-guide__article-content h2,
      .carer-guide__article-content h3,
      .carer-guide__article-content h4 {
        padding: 0.5rem 1.5rem; } }
  .carer-guide__article-content ul,
  .carer-guide__article-content ol {
    padding: 0.75rem 0.75rem 0.75rem 1.5rem; }
    @media (min-width: 500px) {
      .carer-guide__article-content ul,
      .carer-guide__article-content ol {
        padding: 0.75rem 1.5rem 0.75rem 2.5rem; } }
  .carer-guide__article-content > h1,
  .carer-guide__article-content h2 {
    text-align: left;
    margin: 0; }
    .carer-guide__article-content > h1,
    .carer-guide__article-content h2 {
      font-size: 24px;
      line-height: 33.6px; }
      @media screen and (min-width: 500px) {
        .carer-guide__article-content > h1,
        .carer-guide__article-content h2 {
          font-size: calc( 24px + 12 * ((100vw - 500px) / 268));
          line-height: calc( ( 24px + 12 * ((100vw - 500px) / 268) ) * 1.4); } }
      @media screen and (min-width: 768px) {
        .carer-guide__article-content > h1,
        .carer-guide__article-content h2 {
          font-size: 36px;
          line-height: 50.4px; } }
  .carer-guide__article-content > h3 {
    text-align: left;
    margin: 0; }
    .carer-guide__article-content > h3 {
      font-size: 18px;
      line-height: 25.2px; }
      @media screen and (min-width: 500px) {
        .carer-guide__article-content > h3 {
          font-size: calc( 18px + 6 * ((100vw - 500px) / 268));
          line-height: calc( ( 18px + 6 * ((100vw - 500px) / 268) ) * 1.4); } }
      @media screen and (min-width: 768px) {
        .carer-guide__article-content > h3 {
          font-size: 24px;
          line-height: 33.6px; } }
  .carer-guide__article-content > blockquote {
    font-weight: 700; }
  .carer-guide__article-content img {
    max-width: 100%; }

/* Glide */
.glide {
  position: relative;
  width: 100%;
  box-sizing: border-box; }
  .glide * {
    box-sizing: inherit; }
  .glide__track {
    overflow: hidden; }
  .glide__slides {
    position: relative;
    width: 100%;
    list-style: none;
    backface-visibility: hidden;
    transform-style: preserve-3d;
    touch-action: pan-Y;
    overflow: hidden;
    padding: 0;
    white-space: nowrap;
    display: flex;
    flex-wrap: nowrap;
    will-change: transform; }
    .glide__slides--dragging {
      user-select: none; }
  .glide__slide {
    width: 100%;
    height: 100%;
    flex-shrink: 0;
    white-space: normal;
    user-select: none;
    -webkit-touch-callout: none;
    -webkit-tap-highlight-color: transparent; }
    .glide__slide a {
      user-select: none;
      -webkit-user-drag: none;
      -moz-user-select: none;
      -ms-user-select: none; }
  .glide__arrows {
    -webkit-touch-callout: none;
    user-select: none; }
  .glide__bullets {
    -webkit-touch-callout: none;
    user-select: none; }
  .glide--rtl {
    direction: rtl; }

#meet-the-carers {
  position: relative; }
  #meet-the-carers .landscape {
    display: none; }
  @media only screen and (min-width: 900px) {
    #meet-the-carers:before {
      background-image: url(/static/images/devices/tablet.e958143bae81769cd0ccbf1f6495db77.png);
      background-position: center bottom;
      background-repeat: no-repeat;
      background-size: 800px auto;
      bottom: 180px;
      content: "";
      display: block;
      height: 550px;
      left: 50%;
      pointer-events: none;
      position: absolute;
      transform: translateX(-400px);
      width: 800px;
      z-index: 5; }
    #meet-the-carers:after {
      background-image: url(/static/images/devices/tablet-shadow.fe492019870e1427239d64aa9dc44480.png);
      background-position: center bottom;
      background-repeat: no-repeat;
      background-size: 900px auto;
      bottom: 155px;
      content: "";
      display: block;
      height: 80px;
      left: 50%;
      pointer-events: none;
      position: absolute;
      transform: translateX(-450px);
      width: 900px; }
    #meet-the-carers .landscape {
      display: block;
      position: relative;
      top: 10px; }
    #meet-the-carers .portrait {
      display: none; } }
  #meet-the-carers .glide {
    position: relative;
    display: block;
    box-sizing: border-box;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-touch-callout: none;
    -khtml-user-select: none;
    -ms-touch-action: pan-y;
    touch-action: pan-y;
    -webkit-tap-highlight-color: transparent;
    margin: 0 auto;
    overflow: hidden;
    width: 100%;
    width: 300px; }
    @media only screen and (min-width: 900px) {
      #meet-the-carers .glide {
        margin-top: 20px;
        width: 650px; } }
  #meet-the-carers .glide__slides {
    margin: 40px 0 40px; }
    @media only screen and (min-width: 900px) {
      #meet-the-carers .glide__slides {
        margin: 40px 0 50px; } }
  #meet-the-carers .glide__slide {
    padding: 0; }

.meet-the-carers img.carer,
.meet-the-carers img.cat,
.meet-the-carers img.mouse {
  display: none; }
  @media only screen and (min-width: 900px) {
    .meet-the-carers img.carer,
    .meet-the-carers img.cat,
    .meet-the-carers img.mouse {
      bottom: 0;
      display: block;
      position: absolute;
      z-index: 10; } }

.meet-the-carers img.carer {
  bottom: 91px;
  left: 6%;
  height: auto;
  width: 250px; }

.meet-the-carers img.cat {
  bottom: 118px;
  right: 11%;
  width: 200px; }

.meet-the-carers img.mouse {
  bottom: 142px;
  right: 1%;
  width: 120px; }

.has_carousel {
  overflow-x: hidden; }
  .has_carousel .content::after {
    clear: both;
    content: "";
    display: block; }
  .has_carousel .glide {
    clear: both; }
    .has_carousel .glide__track {
      overflow: visible; }
    .has_carousel .glide__slides {
      display: -webkit-flex; }
    .has_carousel .glide__slide {
      padding: 30px; }
      @media only screen and (min-width: 900px) {
        .has_carousel .glide__slide {
          padding: 30px 0; } }
    .has_carousel .glide__bullets {
      display: flex;
      justify-content: center;
      padding-bottom: 30px; }
    .has_carousel .glide__bullet {
      appearance: none;
      background: transparent;
      color: transparent;
      cursor: pointer;
      border: 2px solid #d35663;
      border-radius: 20px;
      outline: none;
      margin: 0 20px;
      height: 20px;
      padding: 0;
      width: 20px;
      text-indent: -9999em;
      transition: background 0.2s ease-in-out, border-color 0.2s ease-in-out, transform 0.2s ease-in-out; }
      .has_carousel .glide__bullet--active {
        background: #d35663;
        color: #d35663;
        transform: scale(1.5); }
    .has_carousel .glide .panel {
      background: white;
      border-radius: 2px;
      box-shadow: 5px 5px 15px 5px rgba(0, 0, 0, 0.1);
      padding: 40px 20px;
      text-align: center; }
      @media only screen and (min-width: 900px) {
        .has_carousel .glide .panel {
          padding: 50px 100px; } }
      .has_carousel .glide .panel h5.subtitle,
      .has_carousel .glide .panel p.subtitle {
        text-align: center; }
      .has_carousel .glide .panel img {
        display: block;
        margin: 0 auto;
        width: 100%; }
        @media only screen and (min-width: 900px) {
          .has_carousel .glide .panel img {
            margin: 0 auto;
            height: 250px;
            width: auto; } }

.contact-pane {
  text-align: center; }
  .contact-pane > img {
    display: block;
    margin: 0 auto;
    width: 50%; }
    @media only screen and (min-width: 900px) {
      .contact-pane > img {
        margin-bottom: 20px; } }
  .contact-pane > h3 {
    margin-top: 20px; }
  .contact-pane .btn {
    margin: 30px 0;
    width: 100%; }
  .contact-pane .phone {
    font-size: 15px;
    line-height: 20px; }
    .contact-pane .phone > a {
      font-weight: 700;
      text-decoration: none; }

.conversationalForm {
  background: white;
  padding: 20px;
  max-width: 500px; }
  .conversationalForm #conversational_form {
    color: #2e404f;
    font-size: 20px; }
    .conversationalForm #conversational_form > .conversationalFormElement > label {
      margin-right: 10px; }
    .conversationalForm #conversational_form > .conversationalFormElement > input {
      background: none;
      border: none;
      border-bottom: 1px solid #e0e0e0;
      font-size: 20px;
      padding: 0 0 0 10px;
      color: #757575;
      line-height: none;
      border-radius: 0; }
      .conversationalForm #conversational_form > .conversationalFormElement > input:active, .conversationalForm #conversational_form > .conversationalFormElement > input:focus, .conversationalForm #conversational_form > .conversationalFormElement > input:visited {
        outline: none; }
    .conversationalForm #conversational_form > .conversationalFormElement > select {
      padding: 5px 35px 5px 5px;
      font-size: 20px;
      border: none;
      border-bottom: 1px solid #e0e0e0;
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      background: url(/static/images/icons/down-chevron-darkblue.08234bc3e007d9ad9e416b1dbe5eb0b7.svg) no-repeat;
      background-position: bottom 8px right;
      background-size: 20px auto;
      color: #757575; }
      .conversationalForm #conversational_form > .conversationalFormElement > select:active, .conversationalForm #conversational_form > .conversationalFormElement > select:focus, .conversationalForm #conversational_form > .conversationalFormElement > select:visited {
        outline: none; }
    .conversationalForm #conversational_form > .conversationalFormElement > button {
      background-color: #77b0f3;
      color: white;
      width: 80px;
      height: 38px;
      font-size: 18px;
      border-radius: 19px;
      border: none;
      margin-left: calc(100% - 90px); }

.cta {
  position: relative; }
  .cta a {
    color: white;
    white-space: nowrap; }
  @media only screen and (min-width: 900px) {
    .cta.carers-2, .cta.customers-2 {
      min-height: 800px; }
      .cta.carers-2 > .col-asym, .cta.customers-2 > .col-asym {
        padding-top: 80px; } }
  .cta img.furniture {
    display: none; }
    @media only screen and (min-width: 900px) {
      .cta img.furniture {
        display: inline-block;
        margin-top: 20px; } }
  .cta.cta-agencies .image, .cta.cta-how-it-works .image {
    display: none; }
    @media only screen and (min-width: 900px) {
      .cta.cta-agencies .image, .cta.cta-how-it-works .image {
        display: block; }
        .cta.cta-agencies .image img, .cta.cta-how-it-works .image img {
          float: right;
          max-width: 700px;
          width: 140%; } }
  .cta.cta-agencies .col-bisect:last-child, .cta.cta-how-it-works .col-bisect:last-child {
    text-align: center; }
    @media only screen and (min-width: 900px) {
      .cta.cta-agencies .col-bisect:last-child, .cta.cta-how-it-works .col-bisect:last-child {
        text-align: left; } }

.laptop {
  display: none; }
  @media only screen and (min-width: 900px) {
    .laptop {
      display: block;
      height: 600px;
      position: absolute;
      left: 40%;
      top: 90px;
      width: 1004px; }
      .laptop > img {
        position: absolute; }
      .laptop > .shadow {
        bottom: 0;
        left: 0; }
      .laptop > .base {
        bottom: 20px;
        left: 0; }
      .laptop > .screen {
        bottom: 51px;
        left: 0; }
      .laptop > .onboarding {
        width: 700px;
        top: 67px;
        left: 151px;
        height: 435px; }
      .laptop > .carer {
        bottom: -30px;
        width: 300px;
        left: -100px; } }

.cta_btn {
  padding: 14px 28px;
  font-size: 18px;
  max-width: 200px;
  margin-bottom: 15px;
  color: #ffffff;
  background-color: #d35663;
  border-radius: 5px;
  text-decoration: none;
  text-align: center; }
  .cta_btn:focus, .cta_btn:hover {
    color: #ffffff; }
  @media only screen and (min-width: 1060px) {
    .cta_btn {
      display: block;
      width: 100%; } }

.fullbleed {
  background-color: #ece8dd;
  background-position: top center;
  background-repeat: no-repeat;
  background-size: 100% auto;
  border-top: 0; }
  @media only screen and (min-width: 900px) {
    .fullbleed {
      background-size: cover;
      background-position: top center;
      min-height: 40vw; } }
  .fullbleed .content {
    width: calc(100% - 30px);
    float: left;
    margin-left: 15px; }
    @media only screen and (min-width: 900px) {
      .fullbleed .content {
        width: calc(50% - 48px);
        float: left;
        margin-left: 32px; } }
    @media only screen and (min-width: 1060px) {
      .fullbleed .content {
        width: calc(41.66667% - 45.33333px);
        float: left;
        margin-left: 32px;
        margin-left: calc(8.33333% - 34.66667px + 64px); } }
  .fullbleed.photo section {
    padding: 160px 0 40px; }
    .fullbleed.photo section::after {
      clear: both;
      content: "";
      display: block; }
    @media only screen and (min-width: 900px) {
      .fullbleed.photo section {
        padding: 100px 0; } }
  @media only screen and (min-width: 900px) {
    .fullbleed.right section .content {
      margin-left: calc(50% - 48px + 64px); } }
  .fullbleed.video {
    background-color: #2e404f; }
    .fullbleed.video section {
      position: relative; }
      @media only screen and (min-width: 900px) {
        .fullbleed.video section {
          padding: 100px 0; } }
      .fullbleed.video section .controls {
        text-align: center; }
        .fullbleed.video section .controls > a {
          background-image: url(/static/images/icons/play-button-white.6f997c9170aa0a51a90e4fd73e24b1d9.svg);
          background-position: 27px center;
          background-size: 50px 50px;
          background-repeat: no-repeat;
          border: 6px solid white;
          border-radius: 100px;
          color: white;
          display: block;
          margin: 10px auto 30px;
          height: 100px;
          text-indent: -9999em;
          width: 100px; }
          .fullbleed.video section .controls > a:hover {
            opacity: 0.8; }
          .fullbleed.video section .controls > a:active {
            background-image: url(/static/images/icons/play-button-navy.67f814860e6d382b3bc7b3d4db8e2a49.svg);
            opacity: 1;
            border-color: #2e404f; }
        @media only screen and (min-width: 900px) {
          .fullbleed.video section .controls {
            width: calc(50% - 48px);
            float: left;
            margin-left: 32px; }
            .fullbleed.video section .controls > a {
              border-radius: 100px;
              border: 4px solid white;
              height: 100px;
              left: 25%;
              margin: 0;
              position: absolute;
              top: 50%;
              transform: translateY(-50px) translateX(-50px);
              width: 100px; } }
      @media only screen and (min-width: 900px) {
        .fullbleed.video section .content {
          margin-left: calc(50% - 48px + 64px); } }
      .fullbleed.video section .player iframe {
        min-height: 40vh; }
      @media only screen and (min-width: 900px) {
        .fullbleed.video section .player {
          width: calc(50% - 48px);
          float: left;
          margin-left: 32px;
          margin-left: calc(0% - 32px + 64px);
          min-height: 1px;
          width: calc(50% - 48px); }
          .fullbleed.video section .player + .content {
            margin-left: calc(0% - 32px + 64px); }
          .fullbleed.video section .player iframe {
            height: 30vw;
            margin-top: -15vw;
            position: absolute;
            bottom: 0;
            top: 50%;
            width: calc(50% - 48px); } }
  @media only screen and (min-width: 900px) {
    .fullbleed .pane p {
      text-align: left; } }

@media only screen and (min-width: 900px) {
  .large-grid {
    padding-bottom: 40px !important; } }

.large-grid .cell {
  width: calc(100% - 30px);
  float: left;
  margin-left: 15px;
  background: white;
  border-radius: 4px;
  margin-bottom: 5px; }
  @media only screen and (min-width: 900px) {
    .large-grid .cell {
      width: calc(41.66667% - 45.33333px);
      float: left;
      margin-left: 32px;
      background: transparent;
      margin-bottom: 20px; }
      .large-grid .cell:nth-child(2n + 1) {
        margin-left: calc(8.33333% - 34.66667px + 64px);
        clear: left; }
      .large-grid .cell:nth-child(n + 3) {
        margin-top: 60px; } }
  .large-grid .cell > img {
    display: none; }
    @media only screen and (min-width: 900px) {
      .large-grid .cell > img {
        border-radius: 4px;
        display: block;
        margin-bottom: 20px;
        width: 100%; } }
  .large-grid .cell > h3 {
    font-family: "Source Sans Pro", sans-serif;
    font-size: 24px;
    margin-bottom: 0; }
    .large-grid .cell > h3 a {
      display: block;
      font-size: 18px;
      padding: 10px 20px;
      text-decoration: none; }
      @media only screen and (min-width: 900px) {
        .large-grid .cell > h3 a {
          color: #2e404f;
          display: inline-block;
          font-family: "Merriweather", serif;
          font-size: 28px;
          line-height: 38px;
          margin-bottom: 20px;
          font-weight: 500;
          padding: 0;
          text-decoration: none; }
          .large-grid .cell > h3 a:hover {
            color: #d35663; } }
  .large-grid .cell > p {
    display: none; }
    @media only screen and (min-width: 900px) {
      .large-grid .cell > p {
        display: block; } }
  .large-grid .cell > .link-footer {
    display: none;
    font-size: 18px;
    font-weight: 700;
    margin-top: 20px; }
    @media only screen and (min-width: 900px) {
      .large-grid .cell > .link-footer {
        display: block; } }

@media only screen and (min-width: 900px) {
  .small-grid {
    padding-bottom: 40px !important; } }

.small-grid .cell {
  width: calc(50% - 22.5px);
  float: left;
  margin-left: 15px;
  margin-bottom: 20px; }
  @media only screen and (min-width: 900px) {
    .small-grid .cell {
      width: calc(25% - 40px);
      float: left;
      margin-left: 32px; } }
  .small-grid .cell > img {
    max-height: 80px;
    border-radius: 4px;
    display: block;
    margin-bottom: 5px;
    width: 100%;
    object-fit: cover; }
    @media only screen and (min-width: 900px) {
      .small-grid .cell > img {
        margin-bottom: 20px;
        max-height: 160px; } }
  .small-grid .cell > h3 {
    font-family: "Source Sans Pro", sans-serif;
    font-size: 24px;
    margin-bottom: 0;
    text-align: center; }
    .small-grid .cell > h3 a {
      display: block;
      font-size: 22px;
      text-decoration: underline; }
      @media only screen and (min-width: 900px) {
        .small-grid .cell > h3 a {
          padding: 10px 20px;
          color: #2e404f;
          display: inline-block;
          font-family: "Source Sans Pro", sans-serif;
          text-decoration: underline;
          color: #d35663;
          font-size: 28px;
          line-height: 38px;
          margin-bottom: 10px;
          font-weight: 600;
          padding: 0; }
          .small-grid .cell > h3 a:hover {
            color: #d35663; } }
  .small-grid .cell .postamble {
    padding-top: 1rem; }

.header.wrap.scrolled {
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.2); }

#global-header-old > a#simple-cta {
  display: none; }
  @media (min-width: 768px) {
    #global-header-old > a#simple-cta {
      display: block;
      float: right;
      margin-right: 50px;
      margin-left: -50px; } }

nav.main {
  display: none; }
  @media (min-width: 768px) {
    nav.main {
      display: block; } }
  nav.main > button {
    appearance: none;
    background: transparent;
    color: #2e404f;
    font-family: "Source Sans Pro", sans-serif;
    font-size: 18px; }
    @media (min-width: 768px) {
      nav.main > button {
        display: none; } }

@media (min-width: 768px) {
  #primary-nav {
    float: left;
    margin-left: 10px;
    margin-top: 16px;
    position: relative;
    z-index: 2; }
    #primary-nav ul {
      display: none; }
    #primary-nav > li {
      height: 40px; }
      #primary-nav > li.dropdown > a {
        padding-right: 20px; }
      #primary-nav > li.dropdown > a:after {
        content: "";
        display: block;
        background-image: url(/static/images/icons/down-chevron-darkblue.08234bc3e007d9ad9e416b1dbe5eb0b7.svg);
        background-repeat: no-repeat;
        position: absolute;
        right: 0;
        top: 17px;
        width: 16px;
        height: 16px; }
    #primary-nav li {
      position: relative;
      background-image: none;
      padding: 0;
      display: inline-block;
      margin-right: 30px; }
      #primary-nav li > a {
        color: #2e404f;
        display: block;
        font-family: "Source Sans Pro", sans-serif;
        font-size: 17px;
        line-height: 28px;
        padding: 5px 0 0;
        text-decoration: none;
        width: calc(100% + 40px); } }
      @media only screen and (min-width: 768px) and (min-width: 1060px) {
        #primary-nav li > a {
          font-size: 20px; } }

@media (min-width: 768px) {
        #primary-nav li > a:hover {
          color: #d35663; }
      #primary-nav li.active {
        border-bottom: 2px solid #d35663; }
        #primary-nav li.active:hover > ul {
          border-top: 2px solid #d35663; }
      #primary-nav li.parent > a:first-child {
        text-transform: uppercase;
        color: #7c8992;
        font-weight: normal;
        font-size: 16px; } }
      @media only screen and (min-width: 768px) and (min-width: 1060px) {
        #primary-nav li.parent > a:first-child {
          font-size: 18px; } }

@media (min-width: 768px) {
    #primary-nav > li:hover a:after {
      background-image: url(/static/images/icons/down-chevron-red.30546584a309e1556513fb94af43ca99.svg);
      transform: rotate(180deg);
      top: 8px; }
    #primary-nav > li:hover > ul {
      display: block;
      background: #ffffff;
      position: absolute;
      top: 38px;
      left: 0px;
      padding: 15px 0 15px 25px;
      box-shadow: 3px 5px 7px -4px #878787; }
      #primary-nav > li:hover > ul > li > a {
        font-weight: 100; }
    #primary-nav > li:hover > ul > li > ul {
      display: block;
      background: #ffffff;
      position: absolute;
      padding: 5px 15px 15px 45px;
      left: -25px;
      box-shadow: 3px 5px 7px -4px #878787; }
      #primary-nav > li:hover > ul > li > ul li {
        width: 150px; }
        #primary-nav > li:hover > ul > li > ul li > a {
          font-weight: 100; }
    #primary-nav ul li {
      width: 185px;
      float: none;
      display: block;
      position: relative; } }

@media only screen and (min-width: 1060px) {
  #primary-nav {
    margin-left: 10px; } }

nav.mobile {
  background: #2e404f;
  bottom: 0;
  color: #f5f3ee;
  display: none;
  padding: 20px 30px 60px;
  position: fixed;
  overflow-y: scroll;
  left: 0;
  right: 0;
  top: 0;
  z-index: 9999; }
  nav.mobile ul > li > ul {
    display: none;
    padding: 0;
    margin: 15px 0; }
    nav.mobile ul > li > ul.open {
      display: block; }
  nav.mobile.open {
    display: block; }
  @media (min-width: 768px) {
    nav.mobile {
      display: none; } }
  nav.mobile .cta {
    width: 100%;
    margin: 0 0 20px;
    text-align: center; }
  nav.mobile > a {
    color: #f5f3ee;
    display: block;
    margin-bottom: 30px;
    width: 160px; }
  nav.mobile > button {
    background: transparent;
    color: #f5f3ee;
    font-family: "Source Sans Pro", sans-serif;
    font-size: 18px;
    font-weight: 600;
    padding: 10px;
    position: absolute;
    top: 32px;
    right: 20px; }
  nav.mobile > ul {
    list-style: none;
    padding: 0; }
    nav.mobile > ul > li {
      background-image: none;
      padding: 0;
      margin-bottom: 20px; }
      nav.mobile > ul > li.active > a,
      nav.mobile > ul > li.active > button {
        text-decoration: underline !important; }
      nav.mobile > ul > li > a,
      nav.mobile > ul > li > button.dropdownBtn {
        position: relative;
        width: 100%;
        text-align: left;
        color: #ffffff;
        display: block;
        font-size: 32px;
        font-family: "Source Sans Pro", sans-serif;
        line-height: 36px;
        text-decoration: none;
        padding: 0;
        background: transparent; }
      nav.mobile > ul > li button.dropdownBtn:before {
        content: "";
        display: block;
        background-image: url(/static/images/icons/down-chevron-white.1947d9b7c9b84a614e9097d2fb61099f.svg);
        background-repeat: no-repeat;
        position: absolute;
        right: 20px;
        top: 15px;
        width: 22px;
        height: 22px; }
      nav.mobile > ul > li button.dropdownBtn.open:before {
        background-image: url(/static/images/icons/down-chevron-red.30546584a309e1556513fb94af43ca99.svg);
        transform: rotate(180deg);
        top: 2px; }
      nav.mobile > ul > li > ul li {
        display: block; }
        nav.mobile > ul > li > ul li a {
          color: #ffffff;
          text-decoration: none;
          font-size: 22px;
          font-weight: 100;
          font-family: "Source Sans Pro", sans-serif;
          padding: 0; }
        nav.mobile > ul > li > ul li.parent ul {
          display: block;
          margin-left: 15px;
          margin: 0 0 0 10px; }
        nav.mobile > ul > li > ul li.parent > a:first-child {
          text-transform: uppercase;
          color: #7c8992;
          font-weight: normal;
          font-size: 18px; }
  nav.mobile > .helper {
    background: #2e404f;
    background-image: url(/static/images/icons/down-chevron-white.1947d9b7c9b84a614e9097d2fb61099f.svg);
    background-position: bottom 10px center;
    background-size: 20px auto;
    background-repeat: no-repeat;
    bottom: 0;
    color: #f5f3ee;
    font-size: 15px;
    font-weight: 600;
    left: 0;
    padding: 20px 0;
    position: fixed;
    right: 0;
    text-align: center;
    transition: transform 1s ease-in-out; }
  nav.mobile.scrolled > .helper {
    transform: translateY(100%); }

.scrolled nav.mobile {
  top: 0; }

.site-header {
  position: fixed;
  background: #ece8dd;
  border-top: 0;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0);
  transition: box-shadow 0.3s ease-in-out;
  width: 100%;
  height: 104px;
  z-index: 100;
  padding: 0 0.5rem;
  color: #2e404f; }
  @media (min-width: 1024px) {
    .site-header {
      padding: 0 2rem; } }
  .site-header.scrolled {
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.2); }
  .site-header__header {
    max-width: 1300px;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    height: 100%; }
  .site-header__site-logo {
    margin-right: 1rem; }
    @media (min-width: 768px) {
      .site-header__site-logo {
        margin-top: 0.2rem; } }
    .site-header__site-logo.staging {
      font-family: "Comic Sans MS", cursive, sans-serif;
      text-decoration: none;
      font-size: 1rem; }
      @media (min-width: 768px) {
        .site-header__site-logo.staging {
          font-size: 1.25rem; } }
      .site-header__site-logo.staging > a {
        text-decoration: none;
        font-weight: normal; }
  .site-header__header-actions {
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex: 0 0 15%;
    font-family: "Source Sans Pro", sans-serif;
    font-size: 0.75rem;
    font-weight: 600; }
    @media (min-width: 768px) {
      .site-header__header-actions {
        font-size: 0.875rem; } }
    @media (min-width: 1200px) {
      .site-header__header-actions {
        flex: 0 0 40%; } }
    .site-header__header-actions .open-nav {
      display: block;
      font-family: "Source Sans Pro", sans-serif;
      font-size: 0.75rem;
      font-weight: 600;
      color: #2e404f;
      border: none;
      background: transparent; }
      @media (min-width: 768px) {
        .site-header__header-actions .open-nav {
          display: none; } }
    .site-header__header-actions .open-contact {
      display: block;
      background: transparent;
      border: none; }
      @media (min-width: 1200px) {
        .site-header__header-actions .open-contact {
          display: none; } }
      .site-header__header-actions .open-contact__image {
        height: 30px; }
  .site-header__header-ctas {
    display: none;
    justify-content: space-around; }
    @media (min-width: 1200px) {
      .site-header__header-ctas {
        display: flex;
        flex: 0 0 83%; } }
    .site-header__header-ctas .header-cta {
      padding: 0.5rem;
      font-family: "Source Sans Pro", sans-serif;
      background-color: #2e404f;
      color: white;
      font-size: 18px;
      width: 160px;
      border: none;
      border-radius: 5px;
      cursor: pointer; }
      .site-header__header-ctas .header-cta:hover {
        filter: brightness(120%); }
  .site-header__log-in {
    display: none; }
    @media (min-width: 768px) {
      .site-header__log-in {
        display: flex;
        font-size: 18px; } }
    .site-header__log-in > a {
      font-weight: 400;
      color: #2e404f;
      text-decoration: none; }

.mobile-contact {
  display: block;
  visibility: hidden;
  background: rgba(255, 255, 255, 0.8);
  opacity: 0;
  width: 100%;
  padding: 10px;
  font-size: 18px;
  position: absolute;
  top: 100%;
  text-align: center;
  transition: all ease-in-out 0.5s;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24); }
  @media (min-width: 1200px) {
    .mobile-contact {
      display: none; } }
  .mobile-contact.open {
    visibility: visible;
    opacity: 1;
    transition: all ease-in-out 0.5s; }
  .mobile-contact .phone {
    cursor: pointer;
    text-decoration: underline;
    color: #d35663;
    font-weight: 600; }

.nav-desktop {
  display: none; }
  @media (min-width: 768px) {
    .nav-desktop {
      display: block; } }

.site-footer {
  background: #2e404f;
  font-size: 0.7rem;
  line-height: 1rem;
  padding: 1.2rem 0.6rem 0.6rem 0.6rem;
  width: 100%; }
  @media (min-width: 768px) {
    .site-footer {
      padding: 2.25rem 1rem 0.5rem 1rem; } }
  .site-footer__main {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-bottom: 1.2px solid;
    max-width: 1300px;
    margin: auto; }
    @media (min-width: 1024px) {
      .site-footer__main {
        flex-direction: row;
        padding-bottom: 1.25rem; } }
  .site-footer__left {
    display: flex;
    flex-direction: column;
    color: #ffffff;
    flex: 0 0 100%;
    margin-bottom: 1rem; }
    .site-footer__left > .logo {
      width: 150px; }
    @media (min-width: 1024px) {
      .site-footer__left {
        flex: 0 0 25%;
        margin-right: 1rem; } }
  .site-footer__intro {
    margin: 0.5rem 0 1rem 0; }
  .site-footer__social {
    width: 150px; }
  .site-footer__newsletter {
    padding-top: 1.5rem; }
  .site-footer__navigation {
    flex: 0 0 100%;
    padding-top: 1rem; }
    @media (min-width: 1024px) {
      .site-footer__navigation {
        flex: 0 0 70%; } }
    .site-footer__navigation .listed-navigation {
      flex-wrap: wrap; }
      .site-footer__navigation .listed-navigation .listed-navigation__title {
        margin-bottom: 1.25rem; }
      .site-footer__navigation .listed-navigation .listed-navigation__lists {
        flex: 0 0 50%;
        padding-bottom: 1.25rem; }
        @media (min-width: 768px) {
          .site-footer__navigation .listed-navigation .listed-navigation__lists {
            flex: 0 0 25%; } }
      .site-footer__navigation .listed-navigation .listed-navigation__navigation-links > a {
        margin-bottom: 0.675rem; }
  .site-footer__bottom {
    max-width: 1300px;
    margin: auto;
    padding-top: 0.5rem; }
    .site-footer__bottom > p + p {
      margin-top: 0.5rem; }
      @media (min-width: 768px) {
        .site-footer__bottom > p + p {
          margin-top: 0; } }
  .site-footer__bottom-navigation {
    display: flex;
    flex-direction: column; }
    @media (min-width: 768px) {
      .site-footer__bottom-navigation {
        flex-direction: row; } }
    .site-footer__bottom-navigation > .supercarers-copyright {
      flex: 0 0 100%;
      margin-top: 0.5rem; }
      @media (min-width: 768px) {
        .site-footer__bottom-navigation > .supercarers-copyright {
          flex: 1 0 auto;
          text-align: right;
          font-size: 0.55rem; } }
  .site-footer__company-info {
    font-size: 0.55rem;
    line-height: 0.7rem; }
  .site-footer__bottom-navigation-links {
    flex: 0 0 100%;
    margin-top: 0.5rem; }
    @media (min-width: 768px) {
      .site-footer__bottom-navigation-links {
        flex: 1 0 auto; } }
    .site-footer__bottom-navigation-links .row-navigations {
      justify-content: space-around; }
    .site-footer__bottom-navigation-links .row-navigation__navigation-link {
      letter-spacing: 1px;
      font-size: 0.5rem; }

.hero {
  margin: 0 auto;
  overflow: hidden;
  padding: 0 0 20px;
  position: relative; }
  .hero::after {
    clear: both;
    content: "";
    display: block; }
  @media only screen and (min-width: 900px) {
    .hero {
      max-width: 1360px;
      min-height: 550px;
      padding: 0 0 40px; } }
  .hero h1 {
    margin-bottom: 10px; }
  .hero > .illustration {
    height: 35vh;
    position: relative;
    transform: scale(1.4);
    top: 20px;
    margin: 0 auto;
    max-width: 350px;
    min-height: 210px;
    width: 100%; }
    @media only screen and (min-width: 900px) {
      .hero > .illustration {
        height: auto;
        max-width: 100%;
        min-height: 0;
        margin: 0;
        position: absolute;
        right: 0;
        top: 0;
        transform: scale(1); } }
    .hero > .illustration > img {
      position: absolute;
      width: 100%; }
      .hero > .illustration > img.detail {
        display: none; }
      @media only screen and (min-width: 900px) {
        .hero > .illustration > img {
          height: 480px;
          top: 0;
          left: 30%;
          width: auto;
          z-index: 10; }
          .hero > .illustration > img.detail {
            display: block; } }
  .hero > .content {
    width: calc(100% - 30px);
    float: left;
    margin-left: 15px;
    text-align: center; }
    @media only screen and (min-width: 900px) {
      .hero > .content {
        width: calc(33.33333% - 42.66667px);
        float: left;
        margin-left: 32px;
        margin-left: calc(8.33333% - 34.66667px + 64px);
        padding-top: 20px;
        position: relative;
        z-index: 11;
        text-align: left; } }
  .hero > .ad-image-container {
    width: 100%;
    margin: 0 auto;
    position: relative; }
    @media only screen and (min-width: 500px) {
      .hero > .ad-image-container {
        width: 100%; } }
    @media only screen and (min-width: 900px) {
      .hero > .ad-image-container {
        width: 100%; } }
    @media only screen and (min-width: 1280px) {
      .hero > .ad-image-container {
        width: 1130px; } }
    .hero > .ad-image-container .ad-image {
      position: absolute;
      top: -100px;
      right: 0px;
      height: 100px;
      width: 100px; }
      @media only screen and (min-width: 900px) {
        .hero > .ad-image-container .ad-image {
          top: 310px;
          right: 10px;
          height: 120px;
          width: 120px; } }

@keyframes more-arrow {
  from {
    background-position: bottom 40px center; }
  to {
    background-position: bottom 15px center; } }
  .hero > .see-more {
    background-image: url(/static/images/icons/down-chevron-red.30546584a309e1556513fb94af43ca99.svg);
    background-position: bottom 15px center;
    background-repeat: no-repeat;
    background-size: 20px auto;
    bottom: 0;
    clear: both;
    display: block;
    margin: 0 auto;
    font-size: 15px;
    font-weight: 600;
    padding-bottom: 25px;
    padding-top: 20px;
    color: #d35663;
    width: 100%;
    text-align: center; }
    @media only screen and (min-width: 900px) {
      .hero > .see-more {
        background-position: bottom 40px center;
        margin: 0 auto;
        padding-bottom: 50px;
        padding-top: 0;
        position: absolute;
        animation-delay: 3s;
        animation-duration: 1.5s;
        animation-iteration-count: 5;
        animation-timing-function: ease-out; } }
  .hero .hero-subtitle {
    color: #7c8992;
    font-size: 28px;
    line-height: 38px;
    margin-bottom: 20px; }
  .hero .hero-intro {
    color: #7c8992;
    font-size: 24px;
    line-height: 34px;
    margin: 20px 0; }

.navy .hero h1 {
  color: white; }

.navy .hero > .see-more {
  background-image: url(/static/images/icons/down-chevron-white.1947d9b7c9b84a614e9097d2fb61099f.svg);
  color: white; }

@media only screen and (min-width: 900px) {
  .hero > .see-more {
    animation-name: more-arrow; } }

@media only screen and (min-width: 900px) {
  .care_jobs .illustration > img {
    height: 500px;
    left: 35%; } }

@media only screen and (min-width: 1060px) {
  .care_jobs .illustration > img {
    left: 40%; } }

body.agencies .illustration > img {
  display: block;
  max-height: 220px;
  margin: 0 auto;
  position: relative;
  width: auto; }
  @media only screen and (min-width: 900px) {
    body.agencies .illustration > img {
      height: 500px;
      max-height: none;
      position: absolute;
      left: 55%; } }
  @media only screen and (min-width: 1060px) {
    body.agencies .illustration > img {
      left: 55%; } }

@media only screen and (min-width: 900px) {
  body.agencies .hero > .content {
    width: calc(50% - 48px);
    float: left;
    margin-left: 32px;
    left: calc(8.33333% - 34.66667px + 32px);
    position: relative;
    padding-top: 80px; } }

@media only screen and (min-width: 900px) {
  body.error .hero > .content {
    width: calc(41.66667% - 45.33333px);
    float: left;
    margin-left: 32px;
    padding-top: 65px; } }

body.error .pane p {
  text-align: left; }

.linked-numbers__container {
  color: #394f60;
  display: flex;
  flex-direction: column; }
  @media (min-width: 768px) {
    .linked-numbers__container {
      flex-direction: row; } }

.linked-numbers__item-container {
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: baseline;
  align-items: center;
  border-right: none;
  padding: 0.5rem;
  position: relative; }
  @media (min-width: 768px) {
    .linked-numbers__item-container {
      border-right: 3px solid #cbcbcb;
      flex-direction: column;
      padding: 0; } }
  .linked-numbers__item-container:not(:last-child)::after {
    content: '';
    height: calc(100% - 40px);
    position: absolute;
    left: calc(0.5rem + 23px);
    top: calc(50% + 22px);
    border-right: 3px solid #cbcbcb;
    z-index: 0; }
  @media (min-width: 768px) {
    .linked-numbers__item-container:not(:last-child)::after {
      display: none; } }
  .linked-numbers__item-container:last-child {
    border-right: none;
    padding-bottom: 0; }

.linked-numbers__number {
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: url(/static/images/icons/blue-dot-1.876c951b9f48bcc5f0a2ab1ee95911e1.png) no-repeat;
  background-position: center;
  background-size: 60px;
  position: relative;
  z-index: 1; }

.linked-numbers__text {
  padding: 0.5rem;
  text-align: left; }
  @media (min-width: 768px) {
    .linked-numbers__text {
      text-align: center; } }

.listed-navigation {
  display: flex;
  justify-content: space-between; }
  .listed-navigation__lists {
    display: flex;
    flex-direction: column; }
  .listed-navigation__title {
    color: #ffffff; }
  .listed-navigation__navigation-links {
    display: flex;
    flex-direction: column;
    color: #7c8992; }
    .listed-navigation__navigation-links > a {
      text-decoration: None;
      color: #a7aeb3; }
      .listed-navigation__navigation-links > a:hover {
        color: #ffffff; }
      .listed-navigation__navigation-links > a.white {
        color: #ffffff; }

.longform .hero > .illustration {
  display: none; }
  @media only screen and (min-width: 900px) {
    .longform .hero > .illustration {
      display: block; }
      .longform .hero > .illustration > img {
        left: 50%;
        top: 40px;
        height: 420px; } }

.longform .hero > .content {
  padding-top: 20px; }
  @media only screen and (min-width: 900px) {
    .longform .hero > .content {
      width: calc(50% - 48px);
      float: left;
      margin-left: 32px;
      margin-left: calc(8.33333% - 34.66667px + 64px);
      padding-top: 80px; } }
  .longform .hero > .content ul {
    margin: 20px 0; }

.longform section.overview div.col {
  text-align: left; }
  @media only screen and (min-width: 900px) {
    .longform section.overview div.col {
      width: calc(50% - 48px);
      float: left;
      margin-left: 32px;
      margin-left: calc(8.33333% - 34.66667px + 64px);
      padding-top: 20px; } }

.longform section.overview div.overview-image > img {
  display: none; }

@media only screen and (min-width: 900px) {
  .longform section.overview div.overview-image {
    margin-bottom: -40px;
    width: calc(41.66667% - 45.33333px);
    float: left;
    margin-left: 32px; }
    .longform section.overview div.overview-image > img {
      display: block;
      margin: 0 auto;
      max-height: 400px;
      max-width: 100%; } }

.longform section.content div.col {
  text-align: left; }
  @media only screen and (min-width: 900px) {
    .longform section.content div.col {
      width: calc(83.33333% - 58.66667px);
      float: left;
      margin-left: 32px;
      margin-left: calc(8.33333% - 34.66667px + 64px); } }
  @media only screen and (min-width: 900px) {
    .longform section.content div.col p + h2,
    .longform section.content div.col p + h3,
    .longform section.content div.col p + h4 {
      margin-top: 30px; } }
  .longform section.content div.col h1, .longform section.content div.col h2 {
    text-align: left; }
  .longform section.content div.col img {
    max-width: 100%; }
  .longform section.content div.col em {
    font-style: italic; }
  .longform section.content div.col blockquote {
    font-style: italic;
    margin: 20px; }
  .longform section.content div.col ol,
  .longform section.content div.col ul {
    margin: 20px 20px 40px; }
  .longform section.content div.col ol {
    list-style: decimal;
    padding-left: 20px; }
    .longform section.content div.col ol li {
      margin-bottom: 5px; }
  .longform section.content div.col sup {
    display: inline-block;
    font-size: 13px;
    margin-bottom: -10px;
    position: relative;
    top: -10px; }

.pane.steps,
.pricing {
  text-align: center; }
  .pane.steps > img,
  .pricing > img {
    display: block;
    margin: -40px auto -20px;
    width: 100%; }
  .pane.steps h5.subtitle, .pane.steps p.subtitle,
  .pricing h5.subtitle,
  .pricing p.subtitle {
    color: #2e404f;
    text-align: center; }
  .pane.steps .price,
  .pricing .price {
    font-family: 'Merriweather', serif;
    margin-bottom: 40px; }
    .pane.steps .price:last-child,
    .pricing .price:last-child {
      margin-bottom: 0; }
    .pane.steps .price .qualifier,
    .pricing .price .qualifier {
      font-size: 15px;
      font-style: italic;
      font-weight: 700;
      line-height: 20px; }
    .pane.steps .price .amount,
    .pricing .price .amount {
      color: #d35663;
      font-size: 60px;
      line-height: 60px;
      margin-bottom: 5px; }
      .pane.steps .price .amount::before,
      .pricing .price .amount::before {
        content: '\A3'; }

.pane.steps > img {
  height: 200px;
  margin: 0 auto;
  width: auto; }

.profile {
  min-height: 0;
  background: white;
  border-radius: 7px;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.15);
  padding: 1.2rem;
  margin-top: 1rem; }
  .profile span.photo {
    background-color: #F7F7F7;
    border-color: #2E404F;
    border-radius: 60px;
    border-style: solid;
    border-width: 3px;
    display: block;
    height: 120px;
    margin: 0 auto;
    overflow: hidden;
    width: 120px;
    margin-top: .6rem;
    margin-bottom: 1rem; }
    .profile span.photo img {
      width: 100%; }
  .profile h3 {
    text-align: center;
    font-size: 22px;
    font-weight: 600;
    margin: 10px 0px 0px; }
  .profile h5.subtitle {
    padding: .4rem;
    margin-bottom: 0; }
  .profile ul {
    list-style: none;
    overflow: hidden;
    padding: 10px 15px;
    padding-top: .6rem; }
    .profile ul li {
      padding: .1rem;
      text-align: left;
      font-size: 22px;
      margin-left: -.5rem; }
      .profile ul li:before {
        content: '';
        display: inline-block;
        height: 1em;
        width: 1em;
        background-size: contain;
        background-repeat: no-repeat;
        margin-right: 0.5em; }
      .profile ul li.location:before {
        background-image: url(/static/images/icons/profile/location.1f235b15df6d5c76b634dbd12e30ec18.svg); }
      .profile ul li.care.hourly:before {
        background-image: url(/static/images/icons/profile/hourly.ae44925099e2ce3b3bec87d1eb3b5cf0.svg); }
      .profile ul li.care.night:before {
        background-image: url(/static/images/icons/profile/night-care.14c2a5e94916f1325895017b2ec9d573.svg); }
      .profile ul li.care.live-in:before {
        background-image: url(/static/images/icons/profile/house.3788284809214997125eefc635647f67.svg); }
      .profile ul li.car:before {
        background-image: url(/static/images/icons/profile/car.4d1a02aaca19c7cd7165a9b74d19f549.svg); }
      .profile ul li.pets:before {
        background-image: url(/static/images/icons/profile/pets.4db114edf0171a6afd6731e1ecfabf7d.svg); }
      .profile ul li.no-pets:before {
        background-image: url(/static/images/icons/profile/no-pets.edc5b12ea1240862927f6f18e9a3b104.svg); }
      .profile ul li.language:before {
        background-image: url(/static/images/icons/profile/languages.e51aabfc2eb8e95a4980ff0d2cebca4e.svg); }
      .profile ul li.verified:before {
        background-image: url(/static/images/icons/profile/verified.9c4b2df36f5ae6931f894ba1bb28d18c.svg); }
      .profile ul li.experience:before {
        background-image: url(/static/images/icons/profile/experience.7ae55589e737b1adc8fad3e9816f3991.svg); }
      .profile ul li.experience:before {
        background-image: url(/static/images/icons/profile/experience.7ae55589e737b1adc8fad3e9816f3991.svg); }

.blog-post__container {
  margin: 1rem 0 0;
  color: #4b4d52; }

.blog-post__title {
  max-width: 768px;
  margin: auto;
  text-align: center;
  padding: 0.5rem; }
  .blog-post__title {
    font-size: 24px;
    line-height: 33.6px; }
    @media screen and (min-width: 500px) {
      .blog-post__title {
        font-size: calc( 24px + 12 * ((100vw - 500px) / 268));
        line-height: calc( ( 24px + 12 * ((100vw - 500px) / 268) ) * 1.4); } }
    @media screen and (min-width: 768px) {
      .blog-post__title {
        font-size: 36px;
        line-height: 50.4px; } }

.blog-post__date {
  text-align: center;
  padding: 0 0.5rem 1rem; }
  .blog-post__date {
    font-size: 18px;
    line-height: 25.2px; }
    @media screen and (min-width: 500px) {
      .blog-post__date {
        font-size: calc( 18px + 2 * ((100vw - 500px) / 268));
        line-height: calc( ( 18px + 2 * ((100vw - 500px) / 268) ) * 1.4); } }
    @media screen and (min-width: 768px) {
      .blog-post__date {
        font-size: 20px;
        line-height: 28px; } }

.blog-post__summary {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0.5rem;
  max-width: 768px;
  margin: 1rem auto; }
  @media (min-width: 768px) {
    .blog-post__summary {
      flex-direction: row; } }

@media (min-width: 768px) {
  .blog-post__share {
    padding-right: 1rem;
    margin-right: 1rem;
    border-right: solid 1px #dadada; } }

.blog-post__share-link {
  display: inline-block; }

.blog-post__hero {
  max-width: 1024px;
  display: flex;
  margin: auto;
  position: relative; }

.blog-post__hero-image {
  height: 100%;
  width: 100%; }

.blog-post__hero-image-caption {
  position: absolute;
  top: 101%;
  left: 0.25rem;
  font-size: 0.65rem;
  line-height: 0.8rem;
  color: #4b4d52;
  max-width: 350px; }
  @media (min-width: 768px) {
    .blog-post__hero-image-caption {
      max-width: 100%; } }
  @media (min-width: 1024px) {
    .blog-post__hero-image-caption {
      left: 0; } }

.blog-post__article {
  background: white; }

.blog-post__read-time {
  display: none;
  background: white url(/static/images/illustrations/furniture/blue-dot-squared.d0d23ed834fe67b646f55c096617fe97.png) center center no-repeat;
  border-radius: 50%;
  color: #2e404f;
  height: 160px;
  position: relative;
  width: 160px;
  justify-content: center;
  align-items: center;
  float: right;
  font-size: 0.8rem;
  font-weight: 700;
  font-family: "Merriweather", serif;
  margin: -130px 0.5rem 0 0; }
  @media (min-width: 500px) {
    .blog-post__read-time {
      display: flex; } }

.blog-post__article-content {
  max-width: 1024px;
  margin: auto;
  padding: 1rem 0 2rem; }
  .blog-post__article-content {
    font-size: 18px;
    line-height: 25.2px; }
    @media screen and (min-width: 500px) {
      .blog-post__article-content {
        font-size: calc( 18px + 6 * ((100vw - 500px) / 268));
        line-height: calc( ( 18px + 6 * ((100vw - 500px) / 268) ) * 1.4); } }
    @media screen and (min-width: 768px) {
      .blog-post__article-content {
        font-size: 24px;
        line-height: 33.6px; } }
  .blog-post__article-content > p,
  .blog-post__article-content blockquote,
  .blog-post__article-content h2,
  .blog-post__article-content h3,
  .blog-post__article-content h4 {
    padding: 0.5rem 0.75rem; }
    @media (min-width: 500px) {
      .blog-post__article-content > p,
      .blog-post__article-content blockquote,
      .blog-post__article-content h2,
      .blog-post__article-content h3,
      .blog-post__article-content h4 {
        padding: 0.5rem 1.5rem; } }
  .blog-post__article-content ul,
  .blog-post__article-content ol {
    padding: 0.75rem 0.75rem 0.75rem 1.5rem; }
    @media (min-width: 500px) {
      .blog-post__article-content ul,
      .blog-post__article-content ol {
        padding: 0.75rem 1.5rem 0.75rem 2.5rem; } }
  .blog-post__article-content > h1,
  .blog-post__article-content h2 {
    text-align: left;
    margin: 0; }
    .blog-post__article-content > h1,
    .blog-post__article-content h2 {
      font-size: 24px;
      line-height: 33.6px; }
      @media screen and (min-width: 500px) {
        .blog-post__article-content > h1,
        .blog-post__article-content h2 {
          font-size: calc( 24px + 12 * ((100vw - 500px) / 268));
          line-height: calc( ( 24px + 12 * ((100vw - 500px) / 268) ) * 1.4); } }
      @media screen and (min-width: 768px) {
        .blog-post__article-content > h1,
        .blog-post__article-content h2 {
          font-size: 36px;
          line-height: 50.4px; } }
  .blog-post__article-content > h3 {
    text-align: left;
    margin: 0; }
    .blog-post__article-content > h3 {
      font-size: 18px;
      line-height: 25.2px; }
      @media screen and (min-width: 500px) {
        .blog-post__article-content > h3 {
          font-size: calc( 18px + 6 * ((100vw - 500px) / 268));
          line-height: calc( ( 18px + 6 * ((100vw - 500px) / 268) ) * 1.4); } }
      @media screen and (min-width: 768px) {
        .blog-post__article-content > h3 {
          font-size: 24px;
          line-height: 33.6px; } }
  .blog-post__article-content > blockquote {
    font-weight: 700; }
  .blog-post__article-content img {
    max-width: 100%; }

.board-profile {
  text-align: center;
  margin-bottom: 30px; }
  @media only screen and (min-width: 900px) {
    .board-profile {
      min-height: 300px;
      margin-bottom: 0; } }
  .board-profile h3 {
    text-align: center;
    margin: 0;
    font-size: 23px; }
  .board-profile .subtitle {
    text-align: center;
    margin-bottom: 0px; }
  .board-profile ul.accolades {
    list-style: none;
    font-size: 20px; }
    .board-profile ul.accolades li.accolade {
      margin-bottom: 0px; }

.row-navigation {
  display: flex;
  justify-content: space-between;
  align-items: center; }
  .row-navigation__navigation-link {
    color: #7c8992;
    text-decoration: none; }
  .row-navigation__dot {
    height: 5px;
    width: 5px;
    background-color: #7c8992;
    border-radius: 50%;
    display: inline-block; }
    .row-navigation__dot:last-child {
      display: none; }

ul.module-list {
  width: calc(100% - 30px);
  float: left;
  margin-left: 15px;
  margin-top: 20px; }
  @media only screen and (min-width: 900px) {
    ul.module-list {
      width: calc(83.33333% - 58.66667px);
      float: left;
      margin-left: 32px;
      margin-left: calc(8.33333% - 34.66667px + 64px);
      margin-top: 40px; } }
  ul.module-list li {
    background: white no-repeat;
    background-position: center top;
    margin-bottom: 30px;
    overflow: hidden;
    padding: 40px 20px;
    position: relative; }
    @media only screen and (min-width: 900px) {
      ul.module-list li {
        padding-left: 250px;
        padding-right: 100px; } }
    ul.module-list li > img {
      margin-bottom: -40px;
      margin-top: -60px;
      width: 60%; }
      @media only screen and (min-width: 900px) {
        ul.module-list li > img {
          margin-bottom: 0;
          margin-top: unset;
          margin-right: 50px;
          position: absolute;
          left: 50px;
          top: 50%;
          height: 150px;
          margin-top: -75px;
          width: auto; } }
    ul.module-list li h4 {
      margin-bottom: 10px; }
      @media only screen and (min-width: 900px) {
        ul.module-list li h4 {
          margin: 0; } }

.nav-desktop {
  display: none;
  font-family: "Open Sans", sans-serif; }
  @media (min-width: 768px) {
    .nav-desktop {
      display: block;
      flex: 1; } }
  .nav-desktop__container {
    display: flex;
    justify-content: flex-start; }
  .nav-desktop__drop-down-access {
    cursor: default; }
    .nav-desktop__drop-down-access:after {
      content: "";
      display: block;
      background: url(/static/images/icons/down-chevron-filledwhite.f54a34412424c06e79941fdc455ff1ec.svg) no-repeat center;
      position: absolute;
      right: calc(50% - 8px);
      top: 81%;
      width: 16px;
      height: 16px;
      transform: rotate(180deg);
      opacity: 0; }
    .nav-desktop__drop-down-access:hover .nav-desktop__drop-down-container {
      animation: reveal-drop-down 0.3s ease-in forwards; }
    .nav-desktop__drop-down-access:hover:after {
      animation: reveal-arrow 0.3s ease-in forwards; }

@keyframes reveal-arrow {
  100% {
    opacity: 1; } }

@keyframes reveal-drop-down {
  100% {
    opacity: 1;
    visibility: visible; } }
  .nav-desktop__primary-link-container {
    position: relative;
    padding: 0 0.75rem;
    display: flex; }
    @media (min-width: 1024px) {
      .nav-desktop__primary-link-container {
        padding: 0 1rem; } }
  .nav-desktop__primary-link {
    font-size: 20px;
    font-family: "Source Sans Pro", sans-serif;
    font-weight: 400;
    color: #2e404f;
    text-decoration: none;
    white-space: nowrap;
    padding: 0.5rem 0; }
    .nav-desktop__primary-link:hover {
      color: #2e404f; }
  .nav-desktop__drop-down-container {
    visibility: hidden;
    opacity: 0;
    position: absolute;
    left: calc(50% - 240px);
    top: 100%;
    box-shadow: 0 50px 100px rgba(50, 50, 93, 0.1), 0 15px 35px rgba(50, 50, 93, 0.15), 0 5px 15px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    overflow: hidden;
    width: 480px; }
  .nav-desktop__drop-down-link {
    display: flex;
    text-decoration: none;
    padding: 0.5rem 2rem 0.5rem 2rem; }
    .nav-desktop__drop-down-link:hover .nav-desktop__drop-down-title {
      color: #54748f; }
    .nav-desktop__drop-down-link:hover .nav-desktop__drop-down-subtitle {
      color: #b4bbc0; }
    .nav-desktop__drop-down-link:hover .nav-desktop__drop-down-icon {
      opacity: 0.7; }
  .nav-desktop__drop-down-featured {
    background: #ffffff;
    padding: 1rem 0rem .6rem 0rem; }
  .nav-desktop__drop-down-additional {
    background: #f3f3f3;
    padding: .4rem 0rem .6rem 0rem; }
    .nav-desktop__drop-down-additional .nav-desktop__drop-down-link {
      padding: 0.6rem 2rem 0.6rem 2rem; }
      .nav-desktop__drop-down-additional .nav-desktop__drop-down-link .nav-desktop__drop-down-icon {
        margin-top: .1rem; }
  .nav-desktop__drop-down-item {
    position: relative; }
  .nav-desktop__drop-down-link-greyed:hover .nav-desktop__drop-down-title {
    color: #415a6f; }
  .nav-desktop__drop-down-link-greyed:hover .nav-desktop__drop-down-subtitle {
    color: #8a969e; }
  .nav-desktop__drop-down-link-greyed:hover .nav-desktop__drop-down-icon {
    opacity: 0.7;
    -webkit-filter: saturate(250%);
    -moz-filter: saturate(250%);
    filter: saturate(250%); }
  .nav-desktop__drop-down-icon {
    margin-right: .8rem;
    width: 24px;
    height: 24px;
    margin-top: .4rem; }
  .nav-desktop__drop-down-title {
    font-size: 18px;
    letter-spacing: .4px;
    color: #2e404f;
    display: block;
    text-transform: uppercase;
    white-space: nowrap;
    line-height: 28px; }
  .nav-desktop__drop-down-subtitle {
    font-size: 17px;
    line-height: 22px;
    display: block;
    color: #7c8992;
    font-weight: 500;
    line-height: 27px; }
  .nav-desktop__drop-down-secondary-links-container {
    display: flex;
    margin-top: 0.2rem;
    white-space: nowrap;
    padding: 0 1rem 1rem 3rem;
    margin-left: 0.7rem; }
  .nav-desktop__drop-down-secondary-links-section {
    margin-right: 1rem; }
  .nav-desktop__drop-down-secondary-links-header {
    display: block;
    color: #7c8992;
    text-transform: uppercase;
    font-size: 16px;
    font-weight: 600;
    letter-spacing: .2px; }
  .nav-desktop__drop-down-secondary-link {
    display: block;
    font-size: 16px;
    color: #2e404f;
    text-decoration: none;
    line-height: 32px; }
    .nav-desktop__drop-down-secondary-link:hover {
      color: #7c8992; }

.nav-mobile {
  background: #182a38;
  bottom: 0;
  color: #f5f3ee;
  visibility: hidden;
  opacity: 0;
  padding: 20px 30px 60px;
  position: fixed;
  overflow-y: scroll;
  left: 0;
  right: 0;
  top: 0;
  z-index: 9999;
  width: 100vw;
  outline: none;
  transition: all 0.2s linear; }
  .nav-mobile.open {
    visibility: visible;
    opacity: 1; }
  .nav-mobile.hidden {
    animation: slide-left-and-hide 0.2s ease-out forwards; }
  .nav-mobile.visible {
    animation: slide-right-and-show 0.2s ease-out forwards; }

@keyframes slide-left-and-hide {
  0% {
    visibility: visible; }
  100% {
    visibility: hidden;
    left: -100%; } }

@keyframes slide-right-and-show {
  0% {
    left: -100%; }
  100% {
    left: 0; } }
  .nav-mobile__logo {
    color: #f5f3ee;
    display: block;
    margin-bottom: 30px;
    width: 160px; }
    .nav-mobile__logo:hover {
      color: #f5f3ee; }
  .nav-mobile__close-nav {
    background: transparent;
    color: #f5f3ee;
    font-family: "Source Sans Pro", sans-serif;
    font-size: 18px;
    font-weight: 600;
    padding: 10px;
    position: absolute;
    top: 32px;
    right: 20px;
    border: none; }
  .nav-mobile__main-container {
    padding-top: 4.25vh;
    margin-bottom: 0.2rem; }
  .nav-mobile__main-link {
    width: 100%;
    text-align: left;
    color: #ffffff;
    font-size: 6vh;
    text-decoration: none;
    padding: 0;
    line-height: 8.5vh;
    background: transparent;
    font-family: "Source Sans Pro", sans-serif;
    font-weight: 600;
    border: none;
    outline: none; }
  .nav-mobile__quick-links {
    display: flex;
    flex-wrap: wrap; }
    .nav-mobile__quick-links .link {
      flex: 1 0 50%;
      font-size: 0.8rem;
      line-height: 1.4rem;
      color: #ffffff;
      text-decoration: none; }
  .nav-mobile__cta-container {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100px;
    display: flex;
    justify-content: space-between;
    padding: 1rem 1rem; }
  .nav-mobile__find-care-cta {
    background: #2e404f;
    border-radius: 6px;
    color: white;
    height: 50px;
    padding: 0 20px;
    font-size: 0.8rem;
    text-transform: uppercase;
    font-weight: 600;
    border: none;
    font-family: "Source Sans Pro", sans-serif;
    letter-spacing: 1px; }
  .nav-mobile__sign-in-cta {
    background: none;
    border: none;
    color: white;
    height: 50px;
    padding: 0 20px;
    font-size: 0.8rem;
    text-transform: uppercase;
    font-weight: 600;
    font-family: "Source Sans Pro", sans-serif;
    letter-spacing: 1px; }
  .nav-mobile .vitality-logo-mobile-nav {
    max-height: 30px;
    max-width: 51px;
    vertical-align: middle; }

#testimonials {
  text-align: center; }
  @media only screen and (min-width: 900px) {
    #testimonials {
      padding-bottom: 30px; } }
  #testimonials .testimonial {
    display: none;
    padding: 30px 0;
    text-align: center; }
    #testimonials .testimonial::after {
      clear: both;
      content: "";
      display: block; }
    #testimonials .testimonial:first-child {
      display: block; }
    @media only screen and (min-width: 900px) {
      #testimonials .testimonial {
        display: block; } }
    #testimonials .testimonial .decorated-image {
      margin-bottom: 30px;
      height: 140px;
      position: relative;
      top: 0;
      left: 0; }
      #testimonials .testimonial .decorated-image img.profile {
        position: absolute;
        top: 0;
        left: 50%;
        margin-left: -70px;
        min-height: 0;
        width: 140px;
        height: 140px; }
      #testimonials .testimonial .decorated-image img.speech-bubble {
        position: absolute;
        margin-top: -30px;
        margin-left: -130px;
        top: 0;
        left: 50%;
        width: 140px; }
    #testimonials .testimonial .content {
      margin-bottom: 20px; }
    #testimonials .testimonial h4 {
      margin-bottom: 5px; }
    #testimonials .testimonial .location,
    #testimonials .testimonial .role {
      font-style: italic;
      font-size: 15px; }

.trust-box__container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem 0.5rem;
  justify-content: center; }
  @media (min-width: 1024px) {
    .trust-box__container {
      padding: 2rem; } }
  .trust-box__container > * {
    max-width: 1300px;
    margin: auto; }
  .trust-box__container h2 {
    text-align: center;
    padding-top: 1rem; }
  .trust-box__container .subtitle {
    padding-top: 0.5rem; }
  .trust-box__container .trustpilot-widget {
    padding: 1rem 1rem 0;
    width: 100%;
    display: none; }
    @media (min-width: 768px) {
      .trust-box__container .trustpilot-widget {
        padding: 2rem 0 1rem;
        display: block; } }
    .trust-box__container .trustpilot-widget--mobile {
      display: block; }
      @media (min-width: 768px) {
        .trust-box__container .trustpilot-widget--mobile {
          display: none; } }

.article > img {
  display: inline-block;
  width: 100%; }

.newsletter__title.subtitle {
  color: #ffffff;
  margin-bottom: 1rem; }

.newsletter input {
  line-height: 36px; }

.newsletter__input {
  background: #7c8992; }

.newsletter__submit.btn {
  padding: 8px 10px;
  font-size: 0.75rem; }

.newsletter__privacy input {
  display: inline; }

.newsletter__privacy p {
  font-size: 0.55rem;
  display: inline-block;
  color: #a7aeb3; }
  .newsletter__privacy p > a {
    color: #a7aeb3; }

.newsletter__checkbox input, .newsletter__checkbox label, .newsletter__checkbox p {
  display: inline; }

.secondary-nav {
  background: #182a38;
  bottom: 0;
  color: #f5f3ee;
  visibility: hidden;
  padding: 3rem 0.5rem 0.8rem 2.75rem;
  position: fixed;
  overflow-y: scroll;
  left: 100%;
  top: 0;
  z-index: 9999;
  height: 100vh;
  width: 100vw; }
  .secondary-nav .slideBackBtn {
    position: absolute;
    background: transparent;
    color: #f5f3ee;
    font-family: "Source Sans Pro", sans-serif;
    font-size: 18px;
    font-weight: 600;
    top: 1rem;
    left: 1rem;
    border: none;
    outline: none;
    padding-left: .4rem; }
    .secondary-nav .slideBackBtn:before {
      content: "";
      display: block;
      background-image: url(/static/images/icons/down-chevron-white.1947d9b7c9b84a614e9097d2fb61099f.svg);
      background-repeat: no-repeat;
      position: absolute;
      left: -22px;
      width: 22px;
      height: 22px;
      transform: rotate(90deg); }
  .secondary-nav.open {
    animation: slide-left-and-show 0.2s ease-out forwards; }
  .secondary-nav.close {
    animation: slide-right-and-hide 0.2s ease-out forwards; }

@keyframes slide-left-and-show {
  100% {
    visibility: visible;
    left: 0; } }

@keyframes slide-right-and-hide {
  0% {
    left: 0;
    visibility: visible; }
  100% {
    left: 100%;
    visibility: hidden; } }
  .secondary-nav__main-link {
    position: relative;
    margin-bottom: 0.8rem; }
  .secondary-nav__icon {
    position: absolute;
    left: -50px;
    width: 50px;
    height: 50px;
    top: -7px;
    padding: 14px; }
  .secondary-nav__title {
    display: flex;
    align-items: center;
    text-transform: uppercase;
    font-size: 1rem;
    text-decoration: none;
    color: #ffffff;
    line-height: 1.5rem;
    padding-bottom: 0.1rem;
    letter-spacing: 1.4px; }
    .secondary-nav__title:hover {
      color: #f5f3ee; }
  .secondary-nav__title-chevron {
    margin-left: 10px;
    transform: rotate(-90deg);
    height: 10px;
    width: 10px; }
  .secondary-nav__subtitle {
    display: block;
    font-size: 0.8rem;
    color: #A2A9AF;
    font-family: "Open Sans";
    line-height: 1rem;
    padding-bottom: 0.2rem; }
    .secondary-nav__subtitle:hover {
      color: #a7aeb3; }
  .secondary-nav__section-heading {
    font-size: 0.8rem;
    color: #a7aeb3;
    text-transform: uppercase;
    color: #A2A9AF;
    font-weight: 600;
    letter-spacing: 1.2px; }
  .secondary-nav__link-group {
    margin-bottom: 0.8rem; }
  .secondary-nav__link {
    display: block;
    font-size: 0.8rem;
    color: #ffffff;
    text-decoration: none;
    font-family: "Open Sans"; }
    .secondary-nav__link:hover {
      color: #ffffff; }

.service .page-content > div {
  width: calc(100% - 30px);
  float: left;
  margin-left: 15px; }
  @media only screen and (min-width: 900px) {
    .service .page-content > div {
      width: calc(66.66667% - 53.33333px);
      float: left;
      margin-left: 32px;
      margin-left: calc(16.66667% - 37.33333px + 64px); } }
  .service .page-content > div > img:first-of-type {
    margin-left: -50vw;
    margin-right: -50vw;
    max-width: 100vw;
    position: relative;
    right: -50%;
    width: 100vw; }
    @media only screen and (min-width: 900px) {
      .service .page-content > div > img:first-of-type {
        position: initial;
        right: initial;
        width: initial;
        margin-left: initial;
        margin-right: initial;
        width: 100%; } }

.service .page-content h2,
.service .page-content h3,
.service .page-content h4 {
  margin-top: 20px; }

.service .page-content ul,
.service .page-content ol {
  margin: 40px 0; }
  .service .page-content ul > li,
  .service .page-content ol > li {
    margin-bottom: 10px; }
  .service .page-content ul ul,
  .service .page-content ol ul {
    margin: 20px; }

.service .page-content ol {
  list-style: decimal; }

.service .page-content blockquote {
  border-left: 5px solid #56a6d1;
  font-size: 28px;
  line-height: 38px;
  margin: 30px 0;
  padding-left: 20px; }

.service .page-header .date,
.service .page-header .subhead {
  display: inline-block;
  font-size: 18px; }

.shortform .hero > .illustration {
  display: none; }
  @media only screen and (min-width: 900px) {
    .shortform .hero > .illustration {
      display: block; }
      .shortform .hero > .illustration > img {
        left: 60%;
        top: 40px; } }

.shortform .hero > .content {
  padding-top: 20px; }
  @media only screen and (min-width: 900px) {
    .shortform .hero > .content {
      width: calc(50% - 48px);
      float: left;
      margin-left: 32px;
      margin-left: calc(8.33333% - 34.66667px + 64px);
      padding-top: 80px; } }
  .shortform .hero > .content ul {
    margin: 20px 0; }

.shortform section.overview div.col {
  text-align: left; }
  @media only screen and (min-width: 900px) {
    .shortform section.overview div.col {
      width: calc(50% - 48px);
      float: left;
      margin-left: 32px;
      margin-left: calc(8.33333% - 34.66667px + 64px);
      padding-top: 20px; } }

.shortform section.overview div.overview-image > img {
  display: none; }

@media only screen and (min-width: 900px) {
  .shortform section.overview div.overview-image {
    margin-bottom: -40px;
    width: calc(41.66667% - 45.33333px);
    float: left;
    margin-left: 32px; }
    .shortform section.overview div.overview-image > img {
      display: block;
      margin: 0 auto;
      max-height: 400px;
      max-width: 100%; } }

.shortform section.content div.col {
  text-align: left; }
  @media only screen and (min-width: 900px) {
    .shortform section.content div.col {
      width: calc(83.33333% - 58.66667px);
      float: left;
      margin-left: 32px;
      margin-left: calc(8.33333% - 34.66667px + 64px); } }
  @media only screen and (min-width: 900px) {
    .shortform section.content div.col p + h2,
    .shortform section.content div.col p + h3,
    .shortform section.content div.col p + h4 {
      margin-top: 30px; } }
  .shortform section.content div.col em {
    font-style: italic; }
  .shortform section.content div.col blockquote {
    font-style: italic;
    margin: 20px; }
  .shortform section.content div.col ol,
  .shortform section.content div.col ul {
    margin: 20px 20px 40px; }
  .shortform section.content div.col ol {
    list-style: decimal;
    padding-left: 20px; }
    .shortform section.content div.col ol li {
      margin-bottom: 5px; }
  .shortform section.content div.col sup {
    display: inline-block;
    font-size: 13px;
    margin-bottom: -10px;
    position: relative;
    top: -10px; }

.social {
  display: flex;
  justify-content: space-between; }
  .social__social-link {
    background-position: center center;
    background-repeat: no-repeat;
    background-size: 40px 40px;
    height: 40px;
    width: 40px;
    opacity: 1;
    transition: opacity 0.2s ease-in-out; }
    .social__social-link:hover {
      opacity: 0.5; }
    .social__social-link.facebook {
      background-image: url(/static/images/icons/social/facebook.da4cec000efda168e17cacabbd7f25d5.png); }
    .social__social-link.twitter {
      background-image: url(/static/images/icons/social/twitter.f36e78c0076ebc822d6cbaa3830eb74d.png); }
    .social__social-link.vimeo {
      background-image: url(/static/images/icons/social/vimeo.fab39a9cbb534c5e45926a843c8dd8c1.png); }
    .social__social-link.youtube {
      background-image: url(/static/images/icons/social/youtube.fb7027159cf567c04d34a16043253748.png); }

.breadcrumb {
  display: none;
  text-align: center; }
  @media only screen and (min-width: 900px) {
    .breadcrumb {
      display: block; } }
  .breadcrumb > div {
    font-size: 18px; }
  .breadcrumb .arrow-right {
    margin: 0 5px;
    color: #d35663; }

.postcode_search_form {
  display: inline-block;
  max-width: 345px;
  width: 100%;
  margin-bottom: 15px;
  border-radius: 5px;
  -webkit-box-shadow: 0px 3px 3px 0px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0px 3px 3px 0px rgba(0, 0, 0, 0.2);
  box-shadow: 0px 3px 3px 0px rgba(0, 0, 0, 0.2); }
  @media only screen and (min-width: 1060px) {
    .postcode_search_form {
      max-width: 320px;
      margin-bottom: 37px; } }
  .postcode_search_form form {
    display: flex;
    position: relative;
    background: transparent;
    margin: 0 auto; }
    .postcode_search_form form input {
      font-size: 16px;
      padding: 12px;
      flex: 1;
      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px;
      border-top-right-radius: unset;
      border-bottom-right-radius: unset; }
      .postcode_search_form form input::-webkit-input-placeholder, .postcode_search_form form input::-moz-placeholder, .postcode_search_form form input:-ms-input-placeholder, .postcode_search_form form input::placeholder {
        font-size: 16px; }
    .postcode_search_form form button {
      font-family: "Source Sans Pro", sans-serif;
      background-color: #d35663;
      color: #ffffff;
      font-size: 20px;
      width: 120px;
      min-width: 80px;
      border: none;
      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px;
      padding: 0;
      cursor: pointer; }
      .postcode_search_form form button:active, .postcode_search_form form button:focus {
        outline: none;
        border: none; }
      .postcode_search_form form button:hover {
        background: #da717c;
        color: white;
        box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.4); }
  .postcode_search_form .error-message {
    color: #d35663;
    margin: 10px 0;
    text-align: center;
    font-size: 16px; }

#cta_find_care_container .cta_find_care {
  padding: 14px 28px;
  font-size: 18px;
  max-width: 200px;
  margin-bottom: 15px;
  color: #ffffff;
  background-color: #d35663;
  border-radius: 5px;
  text-decoration: none;
  text-align: center; }
  #cta_find_care_container .cta_find_care:focus, #cta_find_care_container .cta_find_care:hover {
    color: #ffffff; }
  @media only screen and (min-width: 1060px) {
    #cta_find_care_container .cta_find_care {
      display: block;
      width: 100%; } }

#cta_find_care_container img {
  width: 120px;
  margin-left: 20px;
  margin-bottom: 8px;
  vertical-align: middle; }
  @media only screen and (min-width: 1060px) {
    #cta_find_care_container img {
      width: 200px;
      margin-left: 0; } }

.recommended-by__container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column; }
  .recommended-by__container .subtitle {
    color: #7c8992;
    margin: 0.9rem 0; }

.recommended-by__press-container {
  display: block; }
  @media (min-width: 768px) {
    .recommended-by__press-container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;
      width: 100%; } }

.recommended-by__press-image-container {
  text-align: center; }
  @media (min-width: 768px) {
    .recommended-by__press-image-container {
      flex: 1; } }

.recommended-by__press-image {
  height: 100px;
  opacity: 0.6;
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%); }
  @media (min-width: 768px) {
    .recommended-by__press-image {
      max-height: 55px; } }
  @media (min-width: 1024px) {
    .recommended-by__press-image {
      max-height: 80px; } }

.carer-carousel .checklist {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  text-align: left; }
  @media (min-width: 768px) {
    .carer-carousel .checklist {
      flex-direction: row; } }
  @media (min-width: 768px) {
    .carer-carousel .checklist > li {
      flex: 0 0 50%; } }

.carer-carousel__static-cards {
  width: 100%;
  background: url(/static/images/screens/carer-profile-cards.0a587055f79ea261927fe14d7e62095e.png) no-repeat center;
  background-size: contain;
  height: 320px; }
  @media (min-width: 1024px) {
    .carer-carousel__static-cards {
      display: none; } }

.carer-carousel__carousel {
  display: none; }
  @media (min-width: 1024px) {
    .carer-carousel__carousel {
      display: block; } }
  .carer-carousel__carousel.glide .panel {
    padding: 0; }
    @media (min-width: 1024px) {
      .carer-carousel__carousel.glide .panel {
        width: 240px; } }
    .carer-carousel__carousel.glide .panel img {
      height: auto; }
      @media (min-width: 1024px) {
        .carer-carousel__carousel.glide .panel img {
          height: 300px; } }

/**
* INTERCOM LAUNCHER
*/
#chat-prompt {
  background: #56a6d1;
  border-radius: 8px;
  color: white;
  cursor: pointer;
  display: none;
  font-size: 15px;
  padding: 5px 10px;
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 9999; }
  #chat-prompt strong {
    font-weight: bold; }
  #chat-prompt > img {
    position: absolute;
    height: auto;
    top: -19px;
    left: -32px;
    width: 45px; }
  @media only screen and (min-width: 900px) {
    #chat-prompt {
      display: block; } }

.step-by-step__container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column; }

.step-by-step__step-container {
  display: block; }
  @media (min-width: 768px) {
    .step-by-step__step-container {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: row; } }

.step-by-step__step {
  text-align: center;
  position: relative; }
  @media (min-width: 768px) {
    .step-by-step__step {
      flex: 1; } }
  .step-by-step__step > p {
    min-height: 200px; }
  @media (min-width: 768px) {
    .step-by-step__step:not(:first-child):before {
      content: "";
      border-left: solid #7c8992 1px;
      position: absolute;
      top: 120px;
      bottom: 320px;
      left: 0px; } }

.step-by-step__step-image {
  height: 160px; }

.step-by-step__step-title {
  color: #2e404f;
  min-height: 68px; }

.home-page__introduction {
  padding-bottom: 2rem; }
  @media (min-width: 1024px) {
    .home-page__introduction {
      padding: 1rem 2rem 2rem; } }
  .home-page__introduction > * {
    max-width: 1300px;
    margin: auto; }

.home-page__hero {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  max-width: 1300px;
  margin: auto; }
  @media (min-width: 1024px) {
    .home-page__hero {
      flex-direction: row; } }

.home-page__title {
  padding: 1rem 0.5rem 0.5rem; }
  @media (min-width: 1024px) {
    .home-page__title {
      order: -1;
      max-width: 400px; } }

.home-page__h1 {
  font-size: 1.3rem;
  line-height: 45px; }
  @media (min-width: 1024px) {
    .home-page__h1 {
      font-size: 1.8rem;
      line-height: 55px; } }

@media (min-width: 1024px) {
  .home-page__split-title {
    white-space: nowrap; } }

.home-page__subtitle {
  font-size: 0.9rem; }
  @media (min-width: 1024px) {
    .home-page__subtitle {
      font-size: 1rem; } }

.home-page__header-image-container {
  position: relative;
  width: 100%;
  text-align: center; }
  @media (min-width: 1024px) {
    .home-page__header-image-container {
      max-width: 66%;
      min-width: 490px; } }

.home-page__header-image {
  width: 100%; }

.home-page__postcode-search {
  margin: 1rem 0 0; }
  @media (min-width: 1024px) {
    .home-page__postcode-search {
      margin: 1.5rem 0 0; } }
  .home-page__postcode-search input {
    line-height: 1.5rem; }

.home-page .itv-logo {
  position: absolute;
  right: 0.5rem;
  bottom: 0.5rem;
  height: 100px;
  width: 100px; }
  @media (min-width: 1024px) {
    .home-page .itv-logo {
      right: 1.5rem;
      bottom: 1rem;
      height: 120px;
      width: 120px; } }

.home-page__about-us {
  position: relative;
  background-color: #f5f3ee;
  padding: 1rem; }
  .home-page__about-us > * {
    max-width: 1300px;
    margin: auto; }
  .home-page__about-us h2 {
    margin: 1rem 0; }
  .home-page__about-us p {
    margin-bottom: 0.5rem; }

.home-page__cta-how-it-works {
  position: relative;
  background-color: #d35663;
  padding: 1rem;
  color: white; }
  @media (min-width: 1024px) {
    .home-page__cta-how-it-works {
      padding: 2rem; } }
  .home-page__cta-how-it-works > * {
    max-width: 1300px;
    margin: auto; }
  .home-page__cta-how-it-works .two-col__text {
    padding-top: 2rem; }
  .home-page__cta-how-it-works h2 {
    color: white;
    margin-bottom: 1rem; }
  .home-page__cta-how-it-works .subtitle {
    margin-bottom: 0.5rem; }
  .home-page__cta-how-it-works .btn {
    margin: 1.5rem 0;
    font-weight: 700; }

.home-page__carer-carousel {
  position: relative;
  background-color: #f5f3ee;
  padding-bottom: 2rem; }
  .home-page__carer-carousel .carer-carousel__intro {
    padding: 2rem 1rem;
    max-width: 1300px;
    margin: auto; }
  .home-page__carer-carousel h2 {
    margin-bottom: 1rem; }
  .home-page__carer-carousel p {
    margin-bottom: 1rem; }

.faq__header {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem 0.5rem;
  justify-content: center; }
  @media (min-width: 1024px) {
    .faq__header {
      padding: 2rem; } }

.faq__header_content {
  margin: auto; }
  @media (min-width: 768px) {
    .faq__header_content {
      width: calc(50% - 48px); } }
  .faq__header_content h1,
  .faq__header_content p {
    text-align: center;
    min-width: 300px; }

.faq__faq_sections {
  position: relative;
  display: inline-block;
  margin: 2rem 0 0 0;
  width: 100%; }
  .faq__faq_sections h2 {
    margin-bottom: 1rem; }
  .faq__faq_sections p {
    margin-bottom: 1rem; }

.faq__tabs {
  display: flex;
  flex-direction: row;
  align-items: center; }

.category-intro {
  padding: 0rem 0.5rem;
  text-align: center;
  font-style: italic;
  max-width: 1300px;
  margin: auto;
  margin-top: 1rem; }

.tab-intro {
  padding: 0rem 0.5rem;
  text-align: center;
  font-style: italic;
  max-width: 1300px;
  margin: auto;
  margin-top: 1rem; }

.faq_tab {
  position: absolute;
  left: -200vw; }

.tab-panel {
  display: none; }

.tabset > input:first-child:checked ~ .tab-panels > .tab-panel:first-child,
.tabset > input:nth-child(3):checked ~ .tab-panels > .tab-panel:nth-child(2) {
  display: block; }

/*
Styling
*/
.tabset > label {
  position: relative;
  display: inline-block;
  padding: 15px 15px 25px;
  border: 1px solid transparent;
  border-bottom: 0;
  cursor: pointer;
  font-weight: 600; }

.tabset > label::after {
  content: "";
  position: absolute;
  left: 15px;
  bottom: 10px;
  width: 100px;
  height: 4px;
  background: #8d8d8d; }

.tabset > label:hover,
.tabset > input:focus + label {
  color: #58626a; }

.tabset > label:hover::after,
.tabset > input:focus + label::after,
.tabset > input:checked + label::after {
  background: #d35663; }

.tabset > input:checked + label {
  border-color: #ccc;
  border-bottom: 1px solid #fff;
  background: white;
  margin-bottom: -1px; }

.tab-panel {
  background: white;
  border-top: 1px solid #ccc; }

.tabset {
  display: flex;
  flex-wrap: wrap;
  justify-content: center; }

.tab-panels {
  flex-basis: 100%; }

.question_section {
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: center;
  padding: 0rem 0.5rem 0.5rem 0.5rem;
  max-width: 1300px;
  margin: auto; }
  @media (min-width: 1024px) {
    .question_section {
      align-items: center; } }

.question_container {
  display: flex;
  flex-flow: row wrap;
  width: 100%; }

.question_item {
  flex: 0 0 100%;
  margin: 5px 0px; }

.question_title {
  text-align: left;
  font-size: 32px; }
  @media (min-width: 768px) {
    .question_title {
      text-align: center; } }

.faq_link {
  color: #0b2b39;
  font-weight: 600;
  font-family: "Source Sans Pro", serif; }

.question_header {
  margin: 2rem 0rem 0.5rem 0rem; }

.cta_content {
  max-width: 1300px;
  margin: auto;
  padding: 3rem 1rem 2rem 1rem; }
  .cta_content .cta_title {
    color: white;
    text-align: left; }
    @media (min-width: 1024px) {
      .cta_content .cta_title {
        text-align: center; } }
  .cta_content ul {
    display: flex;
    flex-direction: column;
    justify-content: center; }
    @media (min-width: 1024px) {
      .cta_content ul {
        flex-direction: row; } }
    .cta_content ul li {
      flex: 0 0 100%;
      margin: 10px 0px; }
      @media (min-width: 1024px) {
        .cta_content ul li {
          flex: 0 0 calc(33% - 40px);
          margin: 20px; } }
    .cta_content ul .cta_link {
      color: white;
      text-decoration: none; }
    .cta_content ul .cta_subtitle {
      font-size: 18px;
      line-height: 24px;
      margin-top: 0.2rem;
      color: white; }

.cta_section {
  margin-top: 2rem;
  background: #d35663; }

@media (min-width: 768px) {
  body.index div.fullbleed.what-is-supercarers div.seen-on-tv,
  body.waitrose div.fullbleed.what-is-supercarers div.seen-on-tv {
    float: right; } }

body.index .benefits div.image img,
body.waitrose .benefits div.image img {
  width: 100%; }

@media only screen and (min-width: 900px) {
  body.index .hero > > .content,
  body.waitrose .hero > > .content {
    padding-top: 100px; } }

@media only screen and (min-width: 900px) {
  .hero > .illustration > img {
    height: 480px;
    top: 0;
    left: 40%;
    width: auto;
    z-index: 10;
    padding: 2.5rem; } }

.testimonial {
  margin-top: 1.2rem; }
  .testimonial .stars {
    width: 145px;
    margin: 1rem 0rem; }
  .testimonial .name {
    padding: 1rem 0rem;
    font-weight: 700; }

.col-bisect.image img {
  width: 100%; }

.locations ul {
  padding-top: 1rem;
  padding-left: 0; }
  .locations ul li {
    padding: .6rem;
    background: white;
    border-radius: 4px;
    padding-left: 1.2rem;
    list-style: none;
    color: #d55461;
    font-weight: 600; }

ul .region {
  height: 100px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap; }
  ul .region li {
    text-align: left; }

body.how_it_works .hero > .illustration {
  left: 4%;
  transform: scale(1.6); }
  @media only screen and (min-width: 900px) {
    body.how_it_works .hero > .illustration {
      min-height: 0;
      left: inherit;
      transform: none;
      top: -100px; } }

@media only screen and (min-width: 900px) {
  body.how_it_works .hero {
    min-height: 450px; } }

#why-we-are-different div.image img {
  width: 100%; }

#supercarers-difference div.image img {
  display: block;
  margin: 0 auto 20px;
  width: 80%; }
  @media only screen and (min-width: 900px) {
    #supercarers-difference div.image img {
      margin-top: -80px;
      width: 400px; } }

body.care_jobs .hero > .illustration {
  height: 48vh;
  min-height: 320px;
  top: 30px;
  left: -4%; }
  @media only screen and (min-width: 900px) {
    body.care_jobs .hero > .illustration {
      min-height: 0;
      height: auto;
      left: inherit;
      transform: none; } }

body.care_jobs .hero .becomeSuperCarer {
  margin: 20px 0 70px 0; }

body.care_jobs #our-benefits {
  position: relative;
  overflow: hidden; }
  body.care_jobs #our-benefits img.carer {
    display: none; }
    @media only screen and (min-width: 900px) {
      body.care_jobs #our-benefits img.carer {
        display: block;
        position: absolute;
        bottom: 0;
        right: -5%;
        height: 500px; } }

@media only screen and (min-width: 900px) {
  .longform .hero > .illustration > img.dementia-header {
    left: 45%; } }

@media only screen and (min-width: 900px) {
  .longform .hero > .illustration > img.falls-header {
    left: 55%;
    padding-left: 0.5rem; } }

.longform .scrollflex {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 1rem; }

.longform .scrollflex__item {
  border: 1px solid #d2d2d2;
  flex: 1;
  min-width: 290px;
  padding: 0.5rem; }

.longform .centered-content {
  text-align: center; }

.longform .falls-image {
  width: 200px; }

.longform .falls-image-lg {
  width: 300px; }

.longform .two-col-flex {
  display: flex;
  align-items: center;
  margin: 2rem 0.5rem;
  flex-direction: column; }
  @media only screen and (min-width: 900px) {
    .longform .two-col-flex {
      flex-direction: row;
      margin: 2rem 1rem; } }
  .longform .two-col-flex > * {
    padding: 0.5rem; }

.services #available-services .image img {
  width: 100%; }

.services .typical-tasks img.icon {
  position: relative; }
  @media only screen and (min-width: 900px) {
    .services .typical-tasks img.icon {
      position: static; } }
  .services .typical-tasks img.icon[src*="day-care"] {
    left: -25px; }
  .services .typical-tasks img.icon[src*="personal-care"] {
    left: -25px; }
  .services .typical-tasks img.icon[src*="meal-preparation"] {
    left: -30px; }
  .services .typical-tasks img.icon[src*="running-errands"] {
    left: -20px; }
  .services .typical-tasks img.icon[src*="housekeeping"] {
    left: -31px; }
  .services .typical-tasks img.icon[src*="stay-active"] {
    left: -20px; }
  .services .typical-tasks img.icon[src*="transportation"] {
    left: -10px; }
  .services .typical-tasks img.icon[src*="communication"] {
    left: -40px; }
  .services .typical-tasks img.icon + h4 {
    margin-top: -30px; }

.about_us .wrap .board-description {
  margin-top: 35px;
  font-size: 21px;
  font-weight: 600; }

.applications_block__page {
  padding: 2rem 0.5rem;
  background: #2e404f;
  min-height: 65vh;
  display: flex;
  align-items: center;
  justify-content: center; }

.applications_block__content {
  max-width: 768px;
  margin: auto; }

.applications_block__title {
  color: white;
  text-align: center; }

.applications_block__subtitle {
  padding-top: 1rem;
  text-align: center; }

.supercarers-go .go-pricing img.furniture {
  display: none; }
  @media only screen and (min-width: 900px) {
    .supercarers-go .go-pricing img.furniture {
      display: block;
      position: absolute;
      bottom: -100px;
      right: -10px;
      height: auto;
      width: 300px;
      z-index: 5;
      transform: scale(0.9); } }

.supercarers-go .pricing {
  margin-top: -30px; }

.supercarers-go .page-content > div .partner {
  margin: 40px 0 60px;
  text-align: center; }
  .supercarers-go .page-content > div .partner > img {
    vertical-align: middle;
    height: 60px;
    position: relative;
    top: -7px;
    left: 10px;
    transform: none;
    width: auto; }

body.campaign_signup section.signup {
  margin-bottom: 40px; }
  body.campaign_signup section.signup > div {
    width: calc(100% - 30px);
    float: left;
    margin-left: 15px; }
    @media only screen and (min-width: 900px) {
      body.campaign_signup section.signup > div {
        width: calc(83.33333% - 58.66667px);
        float: left;
        margin-left: 32px;
        margin-left: calc(8.33333% - 34.66667px + 64px); } }
    body.campaign_signup section.signup > div > form {
      background: white;
      border-radius: 4px;
      padding: 40px 20px 20px; }
      body.campaign_signup section.signup > div > form::after {
        clear: both;
        content: "";
        display: block; }
      body.campaign_signup section.signup > div > form > div.col {
        width: calc(100% - 30px);
        float: left;
        margin-left: 15px; }
        @media only screen and (min-width: 900px) {
          body.campaign_signup section.signup > div > form > div.col {
            width: calc(50% - 48px);
            float: left;
            margin-left: 32px;
            margin-left: calc(8.33333% - 34.66667px + 64px); } }
      body.campaign_signup section.signup > div > form > div.illustration {
        display: none;
        position: relative; }
        @media only screen and (min-width: 900px) {
          body.campaign_signup section.signup > div > form > div.illustration {
            display: block; } }
        body.campaign_signup section.signup > div > form > div.illustration img {
          position: absolute;
          left: -20%;
          top: -70px;
          width: 140%; }
        @media only screen and (min-width: 900px) {
          body.campaign_signup section.signup > div > form > div.illustration {
            width: calc(41.66667% - 45.33333px);
            float: left;
            margin-left: 32px;
            margin-left: calc(0% - 32px + 64px); } }
      body.campaign_signup section.signup > div > form fieldset {
        margin-top: 40px; }
      body.campaign_signup section.signup > div > form label {
        display: block;
        font-size: 18px;
        margin-bottom: 5px;
        text-align: left; }
      body.campaign_signup section.signup > div > form input[type='text'] {
        border: 1px solid #dadada;
        display: block;
        font-size: 18px;
        margin-bottom: 20px;
        width: 100%; }
      body.campaign_signup section.signup > div > form div.confirm {
        margin-bottom: 40px; }
        body.campaign_signup section.signup > div > form div.confirm input[type='checkbox'] {
          float: left;
          margin-bottom: 10px;
          margin-right: 10px;
          position: relative;
          top: 5px; }
        body.campaign_signup section.signup > div > form div.confirm label {
          cursor: pointer;
          font-size: 15px;
          line-height: 18px; }
        body.campaign_signup section.signup > div > form div.confirm p {
          text-align: left; }
      body.campaign_signup section.signup > div > form div.submit button {
        margin-bottom: 10px;
        width: 100%; }
      body.campaign_signup section.signup > div > form div.submit p {
        font-size: 15px;
        line-height: 18px;
        text-align: center; }
        body.campaign_signup section.signup > div > form div.submit p.error {
          color: #d35663; }

body.waitrose .hero > .illustration {
  height: 48vh;
  min-height: 320px;
  left: 25%; }
  body.waitrose .hero > .illustration > img {
    height: 48vh;
    width: auto; }
  @media only screen and (min-width: 900px) {
    body.waitrose .hero > .illustration {
      min-height: 0;
      left: 20%;
      height: auto;
      transform: none;
      top: -30px; }
      body.waitrose .hero > .illustration > img {
        height: 520px;
        width: auto; } }

body.careers .fullbleed.join-the-team {
  background-image: url(/static/images/photography/supercarers-team.a9b1a3861e5ff2b13920b970e3f72345.jpg); }

body.careers ul.module-list li > img {
  margin-bottom: -20px;
  margin-top: -40px;
  width: 60%; }
  @media only screen and (min-width: 900px) {
    body.careers ul.module-list li > img {
      margin-bottom: 0;
      margin-top: unset;
      margin-right: 50px;
      position: absolute;
      left: 50px;
      top: 50%;
      height: 150px;
      margin-top: -75px;
      width: auto; } }

body.postcode_search {
  margin-top: -53px;
  padding-top: 0;
  overscroll-behavior: none; }
  body.postcode_search #map-header {
    position: relative; }
    body.postcode_search #map-header #map {
      height: 350px;
      opacity: 0.6; }
    body.postcode_search #map-header #fade {
      height: 70px;
      width: 100%;
      position: absolute;
      bottom: 0;
      background: linear-gradient(to bottom, rgba(236, 232, 221, 0), #ece8dd); }
  body.postcode_search #logo svg {
    height: 70px;
    position: absolute;
    color: #2e404f;
    top: 105px;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0; }

body.find_care {
  padding-top: 0; }
  @media (max-width: 899px) {
    body.find_care {
      padding-top: 0; } }

body.signup_confirmation {
  margin-top: -53px;
  padding-top: 0; }

#formCompleteContainer {
  display: flex;
  flex-direction: column;
  align-items: center; }
  #formCompleteContainer #ctaContinueToAppButton {
    display: none;
    margin-top: 20px; }

.care-solved__introduction {
  padding-bottom: 2rem;
  max-width: 1300px;
  margin: auto; }
  @media (min-width: 1024px) {
    .care-solved__introduction {
      padding: 3rem 2rem 2rem; } }
  .care-solved__introduction .subtitle {
    margin: 1rem; }

.care-solved__hero {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column; }
  @media (min-width: 1024px) {
    .care-solved__hero {
      flex-direction: row; } }

.care-solved__title {
  padding: 0.5rem 0 0.5rem 0.5rem; }
  @media (min-width: 1024px) {
    .care-solved__title {
      order: -1;
      max-width: 480px; } }

@media (min-width: 1024px) {
  .care-solved__split-title {
    white-space: nowrap; } }

.care-solved__subtitle {
  margin: 1rem 0;
  display: flex;
  align-items: center;
  flex-wrap: wrap; }
  @media (min-width: 768px) {
    .care-solved__subtitle {
      height: 53px; } }

.care-solved__logo {
  flex: 0 0 50%;
  margin-right: 0.5rem;
  height: 100%; }
  @media (min-width: 768px) {
    .care-solved__logo {
      flex: 1 0 15%;
      max-width: 160px; } }

.care-solved__partner-logo {
  flex: 0 0 30%;
  margin-left: 0.5rem; }
  @media (min-width: 768px) {
    .care-solved__partner-logo {
      flex: 1 0 15%;
      max-width: 91px; } }

.care-solved__get-started.btn {
  border-radius: 1.5rem;
  margin-bottom: 1rem; }

.care-solved__header-image-container {
  position: relative;
  width: 100%;
  text-align: center; }
  @media (min-width: 1024px) {
    .care-solved__header-image-container {
      max-width: 66%;
      min-width: 490px;
      padding-top: 3rem; } }

.care-solved__header-image {
  width: 100%; }

.care-solved__login-portal {
  background-color: #f5f3ee;
  padding: 2rem 1rem 3rem; }
  @media (min-width: 768px) {
    .care-solved__login-portal {
      padding: 2rem 2rem 3rem; } }
  .care-solved__login-portal > * {
    max-width: 1300px;
    margin: auto; }
  .care-solved__login-portal .care-solved-portal__intro {
    display: flex;
    flex-direction: column;
    align-items: center; }
    .care-solved__login-portal .care-solved-portal__intro h2 {
      margin: 1rem 0; }
    .care-solved__login-portal .care-solved-portal__intro p {
      max-width: 750px;
      text-align: center; }

.care-solved__our-service {
  padding: 2rem 1rem; }
  @media (min-width: 768px) {
    .care-solved__our-service {
      padding: 0; } }
  .care-solved__our-service > * {
    max-width: 1300px;
    margin: auto; }
  .care-solved__our-service h2 {
    margin: 1rem 0; }
  .care-solved__our-service p {
    margin-bottom: 0.5rem; }
  .care-solved__our-service .subtitle {
    margin-top: 0.5rem; }
    @media (min-width: 768px) {
      .care-solved__our-service .subtitle {
        margin: 0; } }
  @media (min-width: 768px) {
    .care-solved__our-service .two-col__image {
      padding: 3rem;
      margin: 0; } }
  @media (min-width: 768px) {
    .care-solved__our-service .two-col__text {
      flex: 0 0 40%;
      padding: 2rem; } }

.care-solved__care-journey {
  background: #f5f3ee;
  padding: 2rem 1rem; }
  @media (min-width: 1024px) {
    .care-solved__care-journey {
      padding: 2.5rem; } }
  .care-solved__care-journey > * {
    max-width: 1300px;
    margin: auto; }
  .care-solved__care-journey h2 {
    margin: 1rem 0; }
  @media (min-width: 768px) {
    .care-solved__care-journey p {
      margin-bottom: 1rem; } }
  @media (min-width: 768px) {
    .care-solved__care-journey .step-by-step__intro {
      padding: 0 2rem; } }
  @media (min-width: 1024px) {
    .care-solved__care-journey .step-by-step__intro {
      max-width: 73%; }
      .care-solved__care-journey .step-by-step__intro .subtitle, .care-solved__care-journey .step-by-step__intro h2, .care-solved__care-journey .step-by-step__intro p {
        text-align: center; } }
  .care-solved__care-journey .step-by-step__step-container {
    margin-top: 1rem; }
  .care-solved__care-journey .step-by-step__step {
    padding: 0.5rem 0 0; }
    @media (min-width: 1024px) {
      .care-solved__care-journey .step-by-step__step {
        padding: 1rem 2rem 0; } }
    .care-solved__care-journey .step-by-step__step > p {
      font-size: 0.9rem; }

.care-solved__care-advice {
  padding: 2rem 1rem 2.5rem; }
  .care-solved__care-advice > * {
    max-width: 1300px;
    margin: auto; }
  .care-solved__care-advice h2 {
    margin: 1rem 0; }
  .care-solved__care-advice p {
    margin-bottom: 0.5rem; }
  .care-solved__care-advice .care-guides__container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    width: 100%; }
  .care-solved__care-advice .care-guides__header-section, .care-solved__care-advice .care-guides__title, .care-solved__care-advice .care-guides__subtitle {
    text-align: center; }
  .care-solved__care-advice .care-guides__header-content {
    margin: auto;
    margin-bottom: 2rem;
    max-width: 800px; }
  .care-solved__care-advice .care-guides__col-container {
    display: flex;
    flex-direction: column;
    min-width: 300px;
    max-width: 30%;
    margin: 0.5rem; }
  .care-solved__care-advice .care-guides__image {
    max-height: 433px; }
  .care-solved__care-advice .care-guides__cta {
    text-align: center;
    border-radius: 0 0 5px 5px; }

.care-solved__our-story {
  background: #f5f3ee;
  padding: 2rem 1rem; }
  @media (min-width: 768px) {
    .care-solved__our-story {
      padding: 0; } }
  .care-solved__our-story > * {
    max-width: 1300px;
    margin: auto; }
  .care-solved__our-story .two-col__container {
    justify-content: center; }
  .care-solved__our-story h2 {
    margin: 1rem 0; }
  .care-solved__our-story p {
    margin-bottom: 0.5rem; }
  @media (min-width: 768px) {
    .care-solved__our-story .two-col__text {
      flex: 0 0 40%;
      padding: 2rem; } }
  @media (min-width: 768px) {
    .care-solved__our-story .two-col__image {
      padding: 0 4rem; } }
  @media (min-width: 1024px) {
    .care-solved__our-story .two-col__image {
      flex: 0 0 38%;
      padding: 3rem;
      order: 1;
      margin: 0; } }

.care-solved__advisory-board {
  padding: 2rem 0.5rem; }
  .care-solved__advisory-board > * {
    max-width: 1300px;
    margin: auto; }
  @media (min-width: 1024px) {
    .care-solved__advisory-board .advisory-board__intro {
      max-width: 73%;
      margin: auto; } }
  .care-solved__advisory-board .advisory-board__intro h2 {
    margin: 1rem 0; }
  .care-solved__advisory-board .advisory-board__intro p {
    margin-bottom: 0.5rem; }
  .care-solved__advisory-board .advisory-board__profiles {
    padding: 1rem 0 0; }
    @media (min-width: 1024px) {
      .care-solved__advisory-board .advisory-board__profiles {
        padding: 2rem 2rem 0; } }
  .care-solved__advisory-board .advisory-board__board-profile {
    margin: 0.5rem 0 0; }
  .care-solved__advisory-board .advisory-board__member-info {
    padding-left: 0.5rem; }
    @media (min-width: 768px) {
      .care-solved__advisory-board .advisory-board__member-info {
        padding: 1rem 0; } }

.care-solved__cta-get-started {
  position: relative;
  background-color: #d35663;
  padding: 2rem 1rem;
  color: white; }
  @media (min-width: 1024px) {
    .care-solved__cta-get-started {
      padding: 1rem; } }
  .care-solved__cta-get-started > * {
    max-width: 1300px;
    margin: auto; }
  .care-solved__cta-get-started h2 {
    color: white;
    margin-bottom: 1rem; }
  .care-solved__cta-get-started .btn {
    font-weight: 700; }

.vitality-portal__drop-down-link .nav-desktop__drop-down-title {
  color: #f41c5e; }
